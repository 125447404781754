<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
    data() {
        return {
        
        }
    },
    beforeCreate() {
      Adjust.initSdk({
      appToken: 'iwfshglsy3gg',
      environment: 'sandbox',
      logLevel: 'verbose'
    });
    },
    created() {
      Adjust.initSdk({
      appToken: 'iwfshglsy3gg',
      environment: 'sandbox',
      logLevel: 'verbose'
    });
    },
    methods: {
        
    }
}

</script>

<style lang="scss">

::v-deep .van-radio__label {
  // border: none;
  // background-color: #fff;
  color: #fff !important;
}

.van-radio {
  margin-bottom: .5rem;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
.newbottom {

  width: 100%;
  height: 20rem;
  background-color: #0E161E;
}

.van-overlay {
  z-index: 99 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
.eleclass {
  margin: 0 auto !important;
}


input {
  border: none;
}

* {
  padding: 0;
  margin: 0;
}

div {
  box-sizing: border-box;

}

.display {
  display: flex;
  align-items: center;
}

.displays {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img {
  vertical-align: middle;
}


html {
  background-color: #0A1119;
  font-size: .9rem;
}
</style>
