// 1、创建中文语言包对象
export default {
    heads: {
        title: '首页',
        title1: '公平证明',
        title2: '娱乐场',
        title3: '推广',
        title4: '宝箱',
        title5: '奖励',
        title6: '规则',
        title7: '投注返利',
        title8: '充值返利',
        title9: '活动',
        title10: '支持',
        title11: '登录',
        title12: '注册',
        title13: '钱包',
        title14: '邮箱',
        title15: '输入您的邮箱',
        title16: '密码',
        title17: '输入您的密码',
        title18: '忘记密码?',
        title19: '没有账号? ',
        title20: '去注册',
        title21: '用户名',
        title22: '输入您的用户名',
        title23: '交易密码',
        title24: '输入您的交易密码',
        title25: '邀请码',
        title26: '输入您的邀请码',
        title27: '已有账号?',
        title28: '去登录',
        title29: '忘记密码',
        title30: '验证码',
        title31: '输入您的验证码',
        title32: '新密码',
        title33: '输入您的新密码',
        title34: '确认密码',
        title35: '输入您的确认密码',
        title36: '修改',
        title37: '充值',
        title38: '提现',
        title39: '兑换',
        title40: '加密货币',
        title41: '输入充值金额',
        title42: '确认',
        title43: '输入促销代码或礼品卡代码',
        title44: '充值不能低于',
        title45: '邮箱不能为空',
        title46: '密码不能为空',
        title47: '交易密码不能为空',
        title48: '验证码不能为空',
        title49: '新密码不能为空',
        title50: '确认密码不能为空',
        title51: '两次密码不一致',
        title52: '兑换码不能为空',
        title53: '用户名不能为空',
    },
    ggtext: {
        title: '复制成功',
        title1: '复制失败',
        title2: '加载中...',
        title3: '没有更多了',
    },
    rechange: {
        title: '存款',
        title1: '您的',
        title2: '款地址',
    },
    withdraw: {
        title: '提款',
        title1: '提款地址',
        title2: '银行卡号',
        title3: '金额',
        title4: '请输入交易密码',
        title5: '确认',
        title6: '请先绑定USDT地址',
        title7: '请先绑定银行卡号',
        title8: '金额不能为空',
        title9: '交易密码不能为空',
    },
    user: {
        title: '绑定银行信息',
        title1: '国家',
        title2: '地址',
        title3: '银行名称',
        title4: '银行卡号',
        title5: '姓名',
        title6: '电子邮件',
        title7: '确认',
        title8: '修改',
        title9: '地址不能为空',
        title10: '请选择银行名称',
        title11: '请输入卡号',
        title12: '请输入姓名',
        title13: '请输入邮箱',
        title14: '交易',
        title15: '全部',
        title16: '充值',
        title17: '提现',
        title18: '数量',
        title19: '时间',
        title20: '未充值',
        title21: '成功',
        title22: '失败',
        title23: '待审核',
        title24: '正在付款',
        title25: '游戏记录',
        title26: '下注',
        title27: '利润',
        title28: '资料',
        title29: '绑定银行信息',
        title30: '通知',
        title31: '统计数据',
        title32: '修改交易密码',
        title33: '交易记录',
        title34: '游戏记录',
        title35: '登出',
        title36: '上传失败,请重新上传',
        title37: '用户信息',
        title38: '用户名',
        title39: '消息通知',
        title40: '介绍',
        title41: '详情',
        title42: '统计数据',
        title43: '玩过的游戏',
        title44: '已下注',
        title45: '净利润',
        title46: '过去24小时的统计数据',
        title47: '游戏',
        title48: '最高下注',
        title49: '最高收益',
        title50: '验证码',
        title51: '新密码',
        title52: '确认密码',
        title53: '邮箱不能为空',
        title54: '验证码不能为空',
        title55: '新密码不能为空',
        title56: '确认密码不能为空',
        title57: '两次密码不一致',
    },
    Activity: {
        title: '邀请链接',
        title1: '高效的内部人员',
        title2: '人',
        title3: '有效提升的玩家数量是多少?',
        title4: '下属累计充电',
        title5: '2或以上所述',
        title6: 'SuoordinateAcumwou to the ostas',
        title7: '72或以上',
    },
    award: {
        title: '大于失败金额',
        title1: '礼品金额',
    },
    Betting: {
        title: '投注交易流水奖励',
        title1: '一级直推',
        title2: '二级直推',
        title3: '三级直推',
    },
    home: {
        title: '欢迎回来',
        title1: '请输入搜素内容',
        title2: '娱乐场',
        title3: '访问游戏',
        title4: '体育博彩',
        title5: '近期胜利',
        title6: '总投注',
        title7: '现场投注',
        title8: '高额赌客',
        title9: '幸运赢',
        title10: '游戏',
        title11: '用户',
        title12: '时间',
        title13: '金额',
    },
    games: {
        title: '正式',
        title1: '试玩',
        title2: '开始游戏',
        title3: '改游戏暂无试玩',
    },
    Playground: {
        title: '娱乐场',
        title1: '请输入搜素内容',
        title2: '随机选择',
        title3: '供应商',
        title4: '按条件分类',
        title5: '返回顶部',
    },
    Promotion: {
        title: '推广合作伙伴',
        title1: '创建下面的代码即可赢取免费奖励',
        title2: '邀请链接',
        title3: '直推人数',
        title4: '首次存款人数',
        title5: '存款人数',
        title6: '团队总充值',
        title7: '团队总额',
        title8: '团队成员',
        title9: '数据概览',
        title10: '团队',
        title11: '其他成员',
        title12: '流水',
        title13: '总营业额',
        title14: '直推流水',
        title15: '其他流水',
        title16: '委员会',
        title17: '总佣金',
        title18: '直接推荐佣金',
        title19: '其他佣金',
    },
    RechargeRebate: {
        title: '充值流水奖励',
        title1: '首次存款',
        title2: '报酬',
    },
    rule: {
        title: '需要达到营业额',
        title1: '充值金额要求',
        title2: '每周奖励',
        title3: '每月奖励',
        title4: '会员升级奖励',
        title5: '*本平台每日提现次数、每日提现限额、单次提现限额均无上限。',
    },
    support: {
        title: '公平',
        title1: '游戏公平吗？',
        title2: '我们是一个公平公正的预测竞猜平台，我们的目标是杜绝一切不公平因素，让玩家玩得放心、玩得开心。',
        title3: '我们总共生成了 1000 万个哈希值（生成链是可验证的），每个哈希值对应一个 Crash 乘数。',
        title4: '我们以相反的顺序发布这 1000 万个数字，每个数字对应游戏的一轮（即我们总共有 1000 万轮）。',
        title5: '换言之，每一回合的碰撞次数都是已经存在的，并非游戏开始后才计算，因此玩家可以放心投注。',
        title6: '游戏是否会被平台操纵？',
        title7: '完整性校验值是验证是否存在官方操纵的关键；测试算法如下。',
        title8: '例子：6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: '取一个2^52范围内的随机值，即16^13，即13位的十六进制数（因为hash值是十六进制的，所以2^52===16^13）6b5124897c3c4（0x6b5124897c3c4在十进制中等于1887939992208324）。',
        title10:'将随机值分散到0~1之间，就是除以13个fs中的最大值0x6b5124897c3c4/0x100000000000000。考虑到hash值的离散随机性，我们可以认为任意的hash值都可以转化成0~1之间的随机数，1887939992208324/4503599627370496 = 0.419206889692064。',
        title11: '令庄家优势为 1%。进一步计算 99/(1-X)，其中 X 是步骤 2 中计算出的随机值。当 X 为 0 时，结果为 99；当 X 为 1 时，结果为正无穷大；当 X 为 0.01 时，结果为100；当 X 小于 0.01 时，结果小于 100。',
        title12: '结论：总体的随机数分布为99至正无穷；而当随机数分布为0~0.01时，结果小于100。',
        title13: '99/(1-0.419206889692064) = 170.45656748150867所有小于 100 的值都将设置为 100。换句话说，出现 100 的概率为 1%。将数字四舍五入并除以 100即可得到最终结果。170/100 = 1.70',
        title14: '结论：游戏中使用的哈希值是逆序的，因此通过SHA256算法，可以根据游戏中使用的前一个哈希值计算出该哈希值的下一个哈希值。由于哈希值的键和值是一一对应的，因此我们可以得出这样的结论：如果一个哈希值可以计算出它前面的哈希值，那么这个哈希值在前一个奖金公布时就应该已经生成了。同样，整个哈希链都是在一开始就生成的，一旦生成就无法更改，否则就无法通过SHA256的验证，至于奖金，这只不过是一个概率游戏，崩溃是必然的，官方主办方无法操纵任何游戏设置。因此，CRASH比其他博彩方式更加透明，这是我们游戏建立的基石。',
        title15: '简单计算：',
        title16: '当13位哈希值为8000000000000 = 1.98，0xb000000000 = 3.168，0xc000000000 =3.96时，即第一位大于8((16-8)/16≈0.5)，结果约为2x；当第一位大于b((16-11)/16≈0.3)，结果约为3x；当第一位大于c((16-12)/16≈0.25)，结果约为4x，其余同理。',
        title17: '当13位哈希值为f000000000000 = 15.84,ff00000000000 = 253.44,fff000000',
        title18: '公平证明',
        title19: '联系我们',
        title20: '常见问题',
        title21: '在线支持',
    },
    Treasure: {
        title: '邀请好友获得宝箱',
        title1: '历史',
        title2: '已收取',
        title3: '收取',
        title4: '未收取',
        title5: '活动时间（UTC-3）：',
        title6: '2024/04/26 00:00:00-2028/04/30 23:59:59 二、活动条件：',
        title7: '只有新举报的部下和下属才有资格参加活动三：',
        title8: '1.邀请好友开启宝箱，邀请不同人数可获得相应宝箱，最高金额200万，邀请好友越多，奖励越多；',
        title9: '2、本次活动为平台额外赠送，可同时享受其他奖励及代理商佣金，即直接享受各种乐趣；',
        title10: '3.奖金仅限在app/iOS、APP/Android端手动领取​​，过期奖金将自动发放；',
        title11: '4.本次活动发放的奖金（不含主力）需兑现有效投注，投注范围仅限于老虎机：所有游戏；',
        title12: '5 本活动仅限于账户持有者正常操作，禁止以租借、使用外挂、机器人、不同账户下注、互相摆摊、仲裁、接口、协议、违规、群控等技术手段参与，否则将取消或扣除奖励，冻结账户甚至列入黑名单；',
        title13: '6.为避免文本解释上的分歧，平台保留对本次活动的最终解释权。',
        title14: '宝箱领取记录',
        title15: '数量',
        title16: '时间',
    }
    
}
