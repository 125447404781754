<template>
    <div class="Playgroundbox">

        <div class="mybox ">
            <div class="title">{{ bilist.coin }} {{ $t('withdraw.title') }}</div>

            <div v-if="btype == 1">
                <div class="name">{{ $t('withdraw.title1') }}</div>
                <input type="text" disabled v-model="address">
            </div>
            <div v-if="btype == 0">
                <div class="name">{{ $t('withdraw.title2') }}</div>
                <input type="text" disabled v-model="bankkh">
            </div>
            <div class="name">{{ bilist.coin }} {{ $t('withdraw.title3') }}</div>
            <!-- <div>
                <input type="text" v-model="pic">
                di
            </div> -->
            <div class="sendcode">
                <input style="padding-right: 4rem;" type="text" v-model="pic">
                <div class="codes" @click="maxpic(1)">max</div>
            </div>
            <div class="name">{{ $t('withdraw.title4') }}</div>
            <input type="password" v-model="jypassword">
            <div class="name" v-html="bilist.withdraw_tips"></div>

            <div class="btn" style="margin: 3rem 0rem;color: #000;" @click="getwitrech">{{ $t('withdraw.title5') }}</div>
        </div>
    </div>
</template>

<script>
import { successNotification } from "../utils/el-ementui"
import { getcoin , getmybank , getwitrech} from '@/api/index'
import heads from '@/components/heads.vue';
export default {
    name: 'Home',
    components: {
        heads
    },
    props: ['id'],
    data() {
        return {
            address: '',
            pic: '0.00',
            jypassword: '',
            bankkh: '',
            bilist: {},
            newcoin: '',
            btype: '',
            xinfo:{},
            isclick:1
        }
    },
    async created() {
       await this.getcoin()
       await this.getmybank()
    },
    methods: {
        async getcoin() {
            const res = await getcoin()

            res.data.forEach((i, v) => {
                if (this.id == v) {

                    this.bilist = i

                    this.newcoin = i.coin
                    // console.log(this.newcoin );
                    if (this.bilist.coin == 'THB') {
                        this.btype = 0
                    } else if (this.bilist.coin == 'USDT') {
                        this.btype = 1
                    }
                }
            })

        },
        async getmybank() {

            let data = {
                coin: this.newcoin
            }

            const res = await getmybank(data)


            if (res.data) {
                if (this.newcoin == 'USDT') {
                    this.address = res.data.address
                }
    
                if (this.newcoin == 'THB') {
                    this.bankkh = res.data.card
                }
            }
            this.xinfo = res.data
  
        },
        maxpic() {
				
				if (this.bilist.coin == 'USDT') {
					this.pic = this.bilist.money
					this.zhbi = this.pic
				} else {
					this.pic = this.bilist.money
					this.zhbi = (this.pic / this.bilist.rate).toFixed(2)
				}
			},
            async getwitrech() {

				if(this.btype==1) {  //usdt
					if(!this.address) {
						
						successNotification(this.$t('withdraw.title6'))
						
						setTimeout(()=>{
							
                            this.$router.push('/user')
						},1500)
						
						return
					}
					
				}
				
				if(this.btype==0) {   //银行
					if(!this.bankkh) {
						successNotification(this.$t('withdraw.title7'))
						setTimeout(()=>{
							this.$router.push('/user')
						},1500)
						
						return
					}
				}


				if (!this.pic||parseFloat(this.pic)==0) {
					successNotification(this.$t('withdraw.title8'))
					return
				}


				if (!this.jypassword) {
					successNotification(this.$t('withdraw.title9'))
					return
				}
                   
			 if(this.btype==1){  //usdt
					if(this.isclick==1) {
						this.isclick=0
						let data = {
							type: 0,
							money: this.pic,
							address: this.address,
							coin: this.newcoin,
							password: this.jypassword,
						}
						
						const res = await getwitrech('api/index/withdraw_recharge',data)
						
						successNotification(res.msg)
						setTimeout(()=>{
							this.isclick=1
						},1000)
						if (res.code == 200) {
							setInterval(() => {
								this.$router.go(0)
							}, 1000)
						}
					}
				}else { //下拉银行卡号
					
					if(this.isclick==1) {
						this.isclick=0
						let data = {
							type: 0,
							money: this.pic,
							// address: this.adderess,
							coin: this.newcoin,
							password: this.jypassword,
						}
						
						const res = await getwitrech('api/index/withdraw_recharge',data)
						
						successNotification(res.msg)
						setTimeout(()=>{
							this.isclick=1
						},1000)
						if (res.code == 200) {
							setInterval(() => {
								this.$router.go(0)
							}, 1000)
						}
					}
					
				}
			},
            
    }
}
</script>

<style lang="scss" scoped>
img {
    vertical-align: middle;
}

::v-deep .el-progress-bar__outer {
    height: .5rem !important;
    margin-top: .5rem;
}

.Playgroundbox {

    min-height: 100vh;
    overflow-y: scroll;
    box-sizing: border-box;

    .mybox {
        font-size: .9rem;
        color: #818E9D;
        padding: 0rem;

        .title {
            font-size: 1.1rem;
            color: #fff;
            font-weight: 600;
        }

        .name {
            margin-top: .5rem;
            margin-bottom: .5rem;
        }

        input {
            width: 100%;
            padding: 1rem;
            box-sizing: border-box;
            background-color: #1D2329;
            border-radius: .6875rem;
            color: #fff;
        }

        .sendcode {
            position: relative;

            .codes {
                padding: .5rem 1rem;
                // background-color: #00FF86;
                border-radius: .3rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: .5rem;
                color: #000;
            }
        }

        .btn {
            width: 100%;
            height: 2.8rem;
            background-color: #00FF86;
            text-align: center;
            line-height: 2.8rem;
            font-weight: 600;
            border-radius: .5rem;
            margin-top: 5rem;
        }

    }

}
</style>