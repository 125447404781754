<template>
  <div class="homebox">
    <heads :index="1"></heads>

    <div style="width: 87rem; margin: 0 auto; padding-bottom: 3rem;overflow: hidden;">
      <div class="lbbox">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" loop :show-indicators="false">
          <van-swipe-item v-for="(i, v) in banlist">
            <img style="width: 100%;height: 100%;" :src="'https://www.pggame.gg' + i.image" alt="">
          </van-swipe-item>

        </van-swipe>
      </div>

      <div class="search">
        <div>{{ $t('home.title') }}<span>{{ userinfo.nickname }}</span></div>
        <div class="seabox" @click="tosearch()">
          <img src="../assets/home/sousuo.png" alt="">
          <input type="text" v-model="searchv" :placeholder="$t('home.title1')" >
        </div>
      </div>

      <div class="hall">
        <div class="red" @click="$router.push('/Playground')">
          <div class="display bot">
            <div>{{ $t('home.title2') }}</div>
            <div class="btn">{{ $t('home.title3') }}</div>
          </div>
        </div>
        <div class="green" @click="tozuqiu()">
          <div class="display bot">
            <div>{{ $t('home.title4') }}</div>
            <div class="btn">{{ $t('home.title3') }}</div> 
          </div>
        </div>
      </div>

      <!-- <div class="game1">
        <div class="title">
          <img src="../assets/logo.png" alt="">
          <div>PGgame</div>
        </div>

        <div class="itembox display">
          <div>老虎机大战</div>
          <div>碰撞</div>
          <div>轮盘赌</div>
          <div>骰子</div>
          <div>骰宝</div>
        </div>
      </div> -->

      <div class="game3" style="margin-top: 2rem;">
        <div class="title display" style="font-size: .9rem;">
          <div class="left display">
            <div class="yuan"></div>
            <div style="color: #fff;">{{ $t('home.title5') }}</div>
          </div>
          <div class="right" style="color: #74808F;">
            {{ bettotal }} {{ $t('home.title6') }}
          </div>
        </div>

        <div class="itembox ">
          <div class="gameitem" v-for="(i, v) in winlist"
            :style="{ background: `url(${i.imageUrl})`}" @click="togames(i.id,i.plat_id)">
            <div class="itemname ">
              <div class="display">
                <div class="yello" style="margin-left: 0rem;">
                  <img src="../assets/home/vip.png" alt="">
                  <div>{{ i.level }}</div>
                </div>
                <div class="newname">{{ i.name }}</div>
              </div>
              <div class="money">$ {{ i.win_amount }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="game2" v-for="(i, v) in typegame">
        <div class="title display">
          <div class="left">
            <img src="../assets/logo.png" alt="">
            <div>{{ i.name }}</div>
          </div>
          <div class="right" @click="tosgame(i.id)">
            <div class="btn">{{ $t('home.title3') }}</div>
            <!-- <img style="margin: 0rem .5rem;" src="../assets/home/leftbtn.png" alt="">
            <img src="../assets/home/rightbtn.png" alt=""> -->
          </div>
        </div>

        <div class="itembox display">
          <div class="gameitem" v-for="(item, value) in i.list"
             >
            <!-- <div class="green">Online</div> -->
             <img style="width: 100%;height: 100%;border-radius: .5rem;" :src="item.imageUrl" alt="" @click="togames(item.id,item.plat_id)">
            <div class="itemname display" >
              <div>{{ item.gameName }}</div>
              <img v-if="item.is_like != 1" src="../assets/home/redxin.png" @click="scgame(item.id)" alt="">
              <img v-else src="../assets/scgame.png" @click="qxscgame(item.id)" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="bottitle display">
        <div class="leftbox">
          <div class="item" :class="{ 'items': betindex == 1 }" @click="changeindex(1)">{{ $t('home.title7') }}</div>
          <div class="item" :class="{ 'items': betindex == 2 }" @click="changeindex(2)">{{ $t('home.title8') }}</div>
          <div class="item" :class="{ 'items': betindex == 3 }" @click="changeindex(3)">{{ $t('home.title9') }}</div>
        </div>
        <div class="right">{{ bettotal }} {{ $t('home.title6') }}</div>
      </div>

      <div class="cgtitle display">
        <div style="text-align: left;">{{ $t('home.title10') }}</div>
        <div style="text-align: left;">{{ $t('home.title11') }}</div>
        <div>{{ $t('home.title12') }}</div>
        <div style="text-align: right;">{{ $t('home.title13') }}</div>
      </div>
      <div style="height: 28rem;overflow-y: hidden;">
        <div class="bottombox display" v-for="(i, v) in bottomlist">
        <div style="text-align: left;" class="leftbox itembox">
          <img :src="i.imageUrl" alt="">
          <div class="gamename">{{i.name}}</div>
        </div>
        <div class="centerbox itembox">
          <img class="cenimg" :src="i.avatar" alt="">
          <div class="yello">
            <img :src="i.image" alt="">
            <div>{{i.level}}</div>
          </div>
          <div class="yelboix">{{i.nickname}}</div>
        </div>
        <div class="itembox">{{i.createtime}}</div>
        <div style="text-align: right;color: #00FF86;" class="itembox">${{i.bet_amount}}</div>
      </div>
      </div>
    </div>

    <!-- <div class="newbottom">

    </div> -->

  </div>
</template>

<script>
import { successNotification } from "../utils/el-ementui"
import { getban, getgamedetai, getgamePlat, getlikegame, getqxlikegame, getgamedata } from '../api/index'
import heads from '@/components/heads.vue';
export default {
  name: 'Home',
  components: {
    heads
  },
  data() {
    return {
      hdarr: [],
      isbolean: true,
      searchv: '',
      betindex: 1,
      banlist: [],
      userinfo: {},
      allgeme: {},
      zqdata: null,
      typegame: [],
      bettotal: '',
      bottomlist: [],
      winlist: [],
      tidata: null
    }
  },
  created() {
    clearInterval(this.zqdata)
    this.zqdata = null
    this.getgamedetai()
    this.get_ban()
    this.getgamePlat()
    this.zqdata = setInterval(() => {
      this.getgamedetai()
    }, 30000)


    clearInterval(this.tidata)
    this.tidata = null

    this.bottomlist = []
    this.getgamedata(1)
    this.tidata = setInterval(() => {

      this.getgamedata(1)
      if (this.bottomlist.length > 8) {
        this.bottomlist.splice(0, 8)
      }
      if (this.winlist.length > 8) {
        this.winlist.splice(0, 8)
      }
    }, 5000)


    if (localStorage.getItem('App-Token')) {
      this.userinfo = JSON.parse(localStorage.getItem('storage_data'))
    }
  },
  beforeDestroy() {
    clearInterval(this.zqdata)
    this.zqdata = null
    clearInterval(this.tidata)
    this.tidata = null
  },
  methods: {
    tosearch() {
      this.$router.push({
        path:'/Playground',
        query : {
          search:'search'
        }
      })
    },
    changeindex(index) {
      clearInterval(this.tidata)
      this.tidata = null
      this.betindex = index
      this.winlist = []
      this.bottomlist = []
      this.getgamedata(index + 1)
      this.tidata = setInterval(() => {
        this.betindex = index
        this.getgamedata(index + 1)
        if (this.bottomlist.length > 8) {
          this.bottomlist.splice(0, 8)
        }
        if (this.winlist.length > 8) {
        this.winlist.splice(0, 8)
      }

      }, 5000)

    },
    get_ban() {
      getban().then(res => {
        // console.log(res,'111')
        this.banlist = res.data
      })
        .catch(err => {

        })
    },
    tozuqiu() {
      if (this.allgeme.url) {
        window.location.href = this.allgeme.url
      }
    },
    async getgamedetai() {

      let data = {
        id: 125,
        is_demo: 0
      }

      const res = await getgamedetai(data)

      this.allgeme = res.data
    },
    async getgamePlat() {
      let data = {
        is_index: 1,
        num: 7
      }
      const res = await getgamePlat(data)
      this.typegame = res.data.list

    },
    async scgame(id) {
      let data = {
        game_id: id
      }
      const res = await getlikegame(data)
      await this.getgamePlat()
      successNotification(res.msg)
    },
    async qxscgame(id) {
      let data = {
        game_id: id
      }

      const res = await getqxlikegame(data)
      await this.getgamePlat()
      successNotification(res.msg)
    },
    async getgamedata(type) {
      let data = {
        type,
        num: 8
      }
      const res = await getgamedata(data)

      this.bettotal = res.data.bet_total
      this.bottomlist.push(...res.data.bottom_list)
      this.winlist.push(...res.data.win_list)
    },
    tosgame(id) { // 跳转搜索游戏
      this.$router.push({
        path:'/Playground',
        query : {
          id
        }
      })
			},
      togames(id,paltid) {
        // console.log(id)
        this.$router.push({
        path:'/games',
        query : {
          id,
          paltid
        }
      })
      }
  }
}
</script>

<style lang="scss" scoped>
.display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img {
  vertical-align: middle;
}

.yello {
  width: 3.5rem;
  height: 1.5rem;
  margin-left: .4rem;
  background: linear-gradient(90deg, #FFBF22 0%, #FFDB53 100%);
  display: flex;
  align-items: center;
  padding: 0rem .5rem;
  border-radius: 2px;
  box-sizing: border-box;

  // justify-content: center;
  img {
    width: 1rem;
    height: 1rem;
  }

  div {
    margin-left: .3rem;
    font-size: .8rem;
    color: #0C131B;
    font-weight: 500;
  }
}

.homebox {
  min-height: 100vh;
  overflow-y: scroll;

  .lbbox {
    margin-top: 1.875rem;
    width: 100%;
    height: 13.125rem;
    background: linear-gradient(87deg, #A57AFF 0%, #7938FF 100%);
    border-radius: .5rem;

    .my-swipe {
      width: 100%;
      height: 13.125rem;
    }
  }

  .search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;

    div {
      font-size: 1.1rem;

      span {
        color: #00FF86;
        margin-left: .625rem;
      }
    }

    .seabox {
      position: relative;
      padding: 1rem 0rem;

      img {
        width: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: .5rem;
      }

      input {
        
        border: none;
        background-color: #131A22;
        border-radius: .3rem;
        font-size: .9rem;
        padding: .5rem 0rem;
        padding-left: 2.5rem;
      }

      input[type='text']::-webkit-input-placeholder {
        color: #434D58;
        font-size: 1rem;
      }
    }
  }

  .hall {
    display: flex;
    justify-content: space-between;

    .red,
    .green {
      width: 48%; 
      height: 15rem;
      // background: linear-gradient(87deg, #CA006D 0%, #940050 100%);
      background: url('../assets/dating.png') no-repeat;
      background-size: 100% 100%;
      border-radius: .5rem;
      position: relative;

      .bot {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(255, 255, 255, .2);
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 0rem 0rem .5rem .5rem;
        color: #fff;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.39);
        font-size: 1.2rem;

        .btn {
          padding: .5rem 1rem;
          background-color: rgba(255, 255, 255, .3);
          border-radius: .5rem;
          font-size: .8rem;
        }
      }
    }

    .green {
      // zuqiu.png
      // background: linear-gradient(87deg, #01B559 0%, #01873B 100%);
      background: url('../assets/zuqiu.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .game1 {
    margin-top: 1.5rem;

    .title {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: #fff;

      img {
        width: 1.75rem;
        margin-right: .3rem;
      }
    }

    .itembox {
      margin-top: 1rem;

      div {

        width: 19%;
        border-radius: .5rem;
        height: 5rem;
        text-align: center;
        line-height: 5rem;
        color: #fff;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.22);
        background: linear-gradient(90deg, #378DDE 0%, #1174CF 100%);
      }

      div:nth-child(2) {
        background: linear-gradient(90deg, #03B75D 0%, #088C51 100%);
      }

      div:nth-child(3) {
        background: linear-gradient(90deg, #C21232 0%, #990D28 100%);
      }

      div:nth-child(4) {
        background: linear-gradient(90deg, #274098 0%, #122A80 100%);
      }

      div:nth-child(5) {
        background: linear-gradient(90deg, #855925 0%, #85412E 100%);
      }
    }
  }

  .game2 {
    margin: 1rem 0rem;
    margin-bottom: 3rem;

    .title {
      font-size: 1.25rem;
      color: #fff;

      .left {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: #fff;

        img {
          width: 1.75rem;
          margin-right: .3rem;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .btn {
          padding: .5rem 1.5rem;
          background-color: #1D2329;
          font-size: .75rem;
          border-radius: .3rem;
        }

        img {
          width: 2rem;
          height: 2rem;
        }
      }

    }

    .itembox {
      margin-top: 1rem;

      .gameitem {
        width: 13.5%;
        height: 8.3rem;
        background-color: #fff;
        border-radius: .625rem;
        position: relative;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        //  overflow-x: scroll;
        .green {
          font-size: .7rem;
          width: 4rem;
          height: 1.5rem;
          line-height: 1.5rem;
          margin-top: .5rem;
          margin-left: .5rem;
          background-color: #00FF86;
          border-radius: 1rem;
        }

        .itemname {
          position: absolute;
          bottom: -2rem;
          left: 0;
          width: 100%;
          height: 2rem;
          color: #fff;
          padding-right: .1rem;
          font-size: .9rem;

          img {
            width: 1.2rem;
            height: 1.1rem;
          }
        }
      }

    }
  }

  .game3 {
    // margin-bottom: 4.5rem;


    .yuan {
      width: .6875rem;
      height: .6875rem;
      background-color: #00FF86;
      border-radius: 50%;
      margin-right: .5rem;
    }

    .itembox {
      height: 15rem;
      display: flex;
      align-items: center;
      width: 200rem;
      overflow-x: hidden;
      margin-top: -3rem;

      .gameitem {
        width: 10rem;
        height: 7rem !important;
        background-color: #fff;
        border-radius: .625rem;
        position: relative;
        margin-right: 1rem;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
        .itemname {
          position: absolute;
          bottom: -3.5rem !important;
          left: 0;
          width: 100%;
          // height: 2rem;
          color: #fff;
          font-size: .9rem;

          box-sizing: border-box;

          .newname {
            margin-left: .5rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          }

          .money {
            font-size: 1.1rem;
            color: #00FF86;
            text-align: left;
            margin-top: .2rem;
          }
        }
      }

      .right {
        color: #74808F;
        font-size: .8rem;
      }
    }
  }

  .bottitle {
    width: 100%;
    background-color: #131A22;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: .3rem;
    margin-top: 6rem;

    .leftbox {
      display: flex;
      align-items: center;
      font-size: 1rem;

      .item {
        color: #fff;
        margin-right: 2rem;
      }

      .items {
        color: #00FF86;
        margin-right: 2rem;
      }
    }

    .right {
      color: #74808F;
      font-size: .8rem;
    }

  }

  .cgtitle {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;

    div {
      width: 25%;
    }
  }

  .bottombox {
    background-color: #131A22;
    border-radius: .3rem;
    padding: 1rem 1rem;
    font-size: .9rem;

    .leftbox {
      display: flex;
      align-items: center;

      img {
        width: 1.625rem;
        height: 1.3125rem;
        background-color: #fff;
        border-radius: 2px;
      }
    }

    .centerbox {

      display: flex;
      align-items: center;

      .cenimg {
        width: 1.5rem;
        height: 1.5rem;
        background-color: #fff;
        border-radius: 2px;
      }

      .yelboix {
        text-align: left;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        margin-left: .5rem;
      }

      // .yello {
      //   width: 3.5rem;
      //   height: 1.5rem;
      //   margin-left: .4rem;
      //   background: linear-gradient(90deg, #FFBF22 0%, #FFDB53 100%);
      //   display: flex;
      //   align-items: center;
      //   padding: 0rem .5rem;
      //   border-radius: 2px;
      //   box-sizing: border-box;
      //   // justify-content: center;
      //   img {
      //     width: 1rem;
      //     height: 1rem;
      //   }
      //   div {
      //     margin-left: .3rem;
      //     font-size: .8rem;
      //     color: #0C131B;
      //     font-weight: 500;
      //   }
      // }
    }

    .itembox {
      width: 25%;
      font-size: .9rem;
      color: #818E9D;
      font-weight: bold;

      .gamename {
        font-size: 1rem;
        color: #fff;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        margin-left: .5rem;

      }
    }
  }
}
</style>