// 1、创建中文语言包对象
export default {
    heads: {
        title: 'หน้าแรก',
        title1: 'การพิสูจน์ความยุติธรรม',
        title2: 'คาสิโน',
        title3: 'โปรโมชัน',
        title4: 'กล่องของขวัญ',
        title5: 'รางวัล',
        title6: 'กฎ',
        title7: 'รางวัลการคืนเงินจากการเดิมพัน',
        title8: 'รางวัลการคืนเงินจากการฝาก',
        title9: 'กิจกรรม',
        title10: 'สนับสนุน',
        title11: 'เข้าสู่ระบบ',
        title12: 'ลงทะเบียน',
        title13: 'กระเป๋าเงิน',
        title14: 'อีเมล',
        title15: 'กรุณากรอกอีเมลของคุณ',
        title16: 'รหัสผ่าน',
        title17: 'กรุณากรอกรหัสผ่านของคุณ',
        title18: 'ลืมรหัสผ่าน?',
        title19: 'ยังไม่มีบัญชี?',
        title20: 'ไปลงทะเบียน',
        title21: 'ชื่อผู้ใช้',
        title22: 'กรุณากรอกชื่อผู้ใช้ของคุณ',
        title23: 'รหัสผ่านการทำธุรกรรม',
        title24: 'กรุณากรอกรหัสผ่านการทำธุรกรรมของคุณ',
        title25: 'รหัสเชิญ',
        title26: 'กรุณากรอกรหัสเชิญของคุณ',
        title27: 'มีบัญชีแล้ว?',
        title28: 'ไปเข้าสู่ระบบ',
        title29: 'ลืมรหัสผ่าน',
        title30: 'รหัสยืนยัน',
        title31: 'กรุณากรอกรหัสยืนยันของคุณ',
        title32: 'รหัสผ่านใหม่',
        title33: 'กรุณากรอกรหัสผ่านใหม่ของคุณ',
        title34: 'ยืนยันรหัสผ่าน',
        title35: 'กรุณากรอกการยืนยันรหัสผ่านของคุณ',
        title36: 'เปลี่ยนแปลง',
        title37: 'ฝากเงิน',
        title38: 'ถอนเงิน',
        title39: 'แลกเปลี่ยน',
        title40: 'สกุลเงินดิจิทัล',
        title41: 'กรุณากรอกจำนวนเงินฝาก',
        title42: 'ยืนยัน',
        title43: 'กรุณากรอกรหัสโปรโมชันหรือรหัสบัตรของขวัญ',
        title44: 'การฝากเงินต้องไม่ต่ำกว่า',
        title45: 'อีเมลห้ามเว้นว่าง',
        title46: 'รหัสผ่านห้ามเว้นว่าง',
        title47: 'รหัสผ่านการทำธุรกรรมห้ามเว้นว่าง',
        title48: 'รหัสยืนยันห้ามเว้นว่าง',
        title49: 'รหัสผ่านใหม่ห้ามเว้นว่าง',
        title50: 'ยืนยันรหัสผ่านห้ามเว้นว่าง',
        title51: 'รหัสผ่านสองครั้งไม่ตรงกัน',
        title52: 'รหัสแลกเปลี่ยนห้ามเว้นว่าง',
        title53: 'ชื่อผู้ใช้ห้ามเว้นว่าง'
    },
    ggtext: {
        title: 'คัดลอกสำเร็จ',
        title1: 'คัดลอกล้มเหลว',
        title2: 'กำลังโหลด...',
        title3: 'ไม่มีข้อมูลเพิ่มเติม'
    },
    rechange: {
        title: 'ฝากเงิน',
        title1: 'ที่อยู่ของคุณ',
        title2: 'ที่อยู่การฝาก'
    },
    withdraw: {
        title: 'ถอนเงิน',
        title1: 'ที่อยู่การถอนเงิน',
        title2: 'หมายเลขบัตรธนาคาร',
        title3: 'จำนวนเงิน',
        title4: 'กรุณากรอกรหัสผ่านการทำธุรกรรม',
        title5: 'ยืนยัน',
        title6: 'กรุณาผูกที่อยู่ USDT ก่อน',
        title7: 'กรุณาผูกหมายเลขบัตรธนาคารก่อน',
        title8: 'จำนวนเงินห้ามเว้นว่าง',
        title9: 'รหัสผ่านการทำธุรกรรมห้ามเว้นว่าง'
    },
    user: {
        title: 'ผูกข้อมูลธนาคาร',
        title1: 'ประเทศ',
        title2: 'ที่อยู่',
        title3: 'ชื่อธนาคาร',
        title4: 'หมายเลขบัตรธนาคาร',
        title5: 'ชื่อ',
        title6: 'อีเมล',
        title7: 'ยืนยัน',
        title8: 'แก้ไข',
        title9: 'ที่อยู่ห้ามเว้นว่าง',
        title10: 'กรุณาเลือกชื่อธนาคาร',
        title11: 'กรุณากรอกหมายเลขบัตร',
        title12: 'กรุณากรอกชื่อ',
        title13: 'กรุณากรอกอีเมล',
        title14: 'ธุรกรรม',
        title15: 'ทั้งหมด',
        title16: 'ฝากเงิน',
        title17: 'ถอนเงิน',
        title18: 'จำนวน',
        title19: 'เวลา',
        title20: 'ยังไม่ได้ฝาก',
        title21: 'สำเร็จ',
        title22: 'ล้มเหลว',
        title23: 'รอการตรวจสอบ',
        title24: 'กำลังชำระเงิน',
        title25: 'ประวัติการเล่นเกม',
        title26: 'เดิมพัน',
        title27: 'กำไร',
        title28: 'ข้อมูล',
        title29: 'ผูกข้อมูลธนาคาร',
        title30: 'การแจ้งเตือน',
        title31: 'สถิติ',
        title32: 'เปลี่ยนรหัสผ่านการทำธุรกรรม',
        title33: 'ประวัติการทำธุรกรรม',
        title34: 'ประวัติการเล่นเกม',
        title35: 'ออกจากระบบ',
        title36: 'อัปโหลดล้มเหลว, กรุณาอัปโหลดอีกครั้ง',
        title37: 'ข้อมูลผู้ใช้',
        title38: 'ชื่อผู้ใช้',
        title39: 'การแจ้งเตือน',
        title40: 'แนะนำ',
        title41: 'รายละเอียด',
        title42: 'สถิติ',
        title43: 'เกมที่เล่น',
        title44: 'เดิมพันแล้ว',
        title45: 'กำไรสุทธิ',
        title46: 'สถิติในช่วง 24 ชั่วโมงที่ผ่านมา',
        title47: 'เกม',
        title48: 'เดิมพันสูงสุด',
        title49: 'กำไรสูงสุด',
        title50: 'รหัสยืนยัน',
        title51: 'รหัสผ่านใหม่',
        title52: 'ยืนยันรหัสผ่าน',
        title53: 'อีเมลห้ามเว้นว่าง',
        title54: 'รหัสยืนยันห้ามเว้นว่าง',
        title55: 'รหัสผ่านใหม่ห้ามเว้นว่าง',
        title56: 'ยืนยันรหัสผ่านห้ามเว้นว่าง',
        title57: 'รหัสผ่านสองครั้งไม่ตรงกัน'
    },
    Activity: {
        title: 'ลิงค์เชิญ',
        title1: 'บุคคลภายในที่มีประสิทธิภาพ',
        title2: 'คน',
        title3: 'จำนวนผู้เล่นที่เพิ่มขึ้นอย่างมีประสิทธิภาพคือเท่าไหร่?',
        title4: 'การชาร์จสะสมของผู้ใต้บังคับบัญชา',
        title5: '2 หรือมากกว่าที่กล่าวถึง',
        title6: 'SuoordinateAcumwou to the ostas',
        title7: '72 หรือมากกว่า'
    },
    award: {
        title: 'มากกว่าจำนวนเงินที่เสีย',
        title1: 'จำนวนเงินของขวัญ'
    },
    Betting: {
        title: 'รางวัลการทำธุรกรรมการเดิมพัน',
        title1: 'การแนะนำระดับ 1',
        title2: 'การแนะนำระดับ 2',
        title3: 'การแนะนำระดับ 3'
    },
    home: {
        title: 'ยินดีต้อนรับกลับ',
        title1: 'กรุณากรอกเนื้อหาการค้นหา',
        title2: 'คาสิโน',
        title3: 'เล่นเกม',
        title4: 'การพนันกีฬา',
        title5: 'ชัยชนะล่าสุด',
        title6: 'เดิมพันรวม',
        title7: 'เดิมพันสด',
        title8: 'นักพนันระดับสูง',
        title9: 'โชคดี',
        title10: 'เกม',
        title11: 'ผู้ใช้',
        title12: 'เวลา',
        title13: 'จำนวนเงิน'
    },
    games: {
        title: 'เกมปกติ',
        title1: 'ทดลองเล่น',
        title2: 'เริ่มเกม',
        title3: 'เกมนี้ไม่มีโหมดทดลองเล่น'
    },
    Playground: {
        title: 'คาสิโน',
        title1: 'กรุณากรอกเนื้อหาการค้นหา',
        title2: 'เลือกแบบสุ่ม',
        title3: 'ผู้ให้บริการ',
        title4: 'จัดหมวดหมู่ตามเงื่อนไข',
        title5: 'กลับไปที่ด้านบน'
    },
    Promotion: {
        title: 'พันธมิตรการโปรโมท',
        title1: 'สร้างรหัสด้านล่างเพื่อรับรางวัลฟรี',
        title2: 'ลิงค์เชิญ',
        title3: 'จำนวนผู้แนะนำโดยตรง',
        title4: 'จำนวนผู้ฝากเงินครั้งแรก',
        title5: 'จำนวนผู้ฝากเงิน',
        title6: 'ยอดรวมการเติมเงินของทีม',
        title7: 'ยอดรวมของทีม',
        title8: 'สมาชิกทีม',
        title9: 'ภาพรวมข้อมูล',
        title10: 'ทีม',
        title11: 'สมาชิกอื่นๆ',
        title12: 'การหมุนเวียน',
        title13: 'ยอดขายรวม',
        title14: 'การหมุนเวียนจากการแนะนำโดยตรง',
        title15: 'การหมุนเวียนอื่นๆ',
        title16: 'คณะกรรมการ',
        title17: 'ค่าคอมมิชชั่นรวม',
        title18: 'ค่าคอมมิชชั่นจากการแนะนำโดยตรง',
        title19: 'ค่าคอมมิชชั่นอื่นๆ'
    },
    RechargeRebate: {
        title: 'รางวัลการเติมเงิน',
        title1: 'ฝากเงินครั้งแรก',
        title2: 'ค่าตอบแทน'
    },
    rule: {
        title: 'ต้องบรรลุยอดหมุนเวียน',
        title1: 'ข้อกำหนดการฝากเงิน',
        title2: 'รางวัลรายสัปดาห์',
        title3: 'รางวัลรายเดือน',
        title4: 'รางวัลการอัปเกรดสมาชิก',
        title5: '*แพลตฟอร์มนี้ไม่มีข้อจำกัดจำนวนการถอนเงินต่อวัน, ขีดจำกัดการถอนเงินต่อวัน, หรือขีดจำกัดการถอนเงินครั้งเดียว'
    },
    support: {
        title: 'ความยุติธรรม',
        title1: 'เกมมีความยุติธรรมไหม?',
        title2: 'เราคือแพลตฟอร์มการคาดการณ์ที่ยุติธรรมและเป็นกลาง เป้าหมายของเราคือการขจัดปัจจัยที่ไม่ยุติธรรมทั้งหมด เพื่อให้ผู้เล่นเล่นได้อย่างสบายใจและสนุกสนาน',
        title3: 'เราสร้างค่าแฮชทั้งหมด 10 ล้านค่าค่าแฮช (ซึ่งสามารถตรวจสอบได้) โดยแต่ละค่าแฮชจะตรงกับตัวคูณของ Crash',
        title4: 'เราปล่อยตัวเลข 10 ล้านตัวนี้ในลำดับย้อนกลับ โดยแต่ละตัวเลขจะตรงกับหนึ่งรอบของเกม (เรามีทั้งหมด 10 ล้านรอบ)',
        title5: 'กล่าวอีกนัยหนึ่ง จำนวนการชนแต่ละรอบนั้นมีอยู่แล้ว และไม่ได้คำนวณหลังจากเริ่มเกม ดังนั้นผู้เล่นสามารถเดิมพันได้อย่างสบายใจ',
        title6: 'เกมจะถูกจัดการโดยแพลตฟอร์มหรือไม่?',
        title7: 'ค่าเช็คความสมบูรณ์เป็นกุญแจในการตรวจสอบการจัดการโดยทางการ; อัลกอริธึมการทดสอบมีดังนี้',
        title8: 'ตัวอย่าง: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: 'เลือกค่าที่สุ่มในช่วง 2^52 หรือ 16^13 ซึ่งคือหมายเลขฐานสิบหก 13 หลัก (เนื่องจากค่าแฮชเป็นหมายเลขฐานสิบหก ดังนั้น 2^52 === 16^13) 6b5124897c3c4 (0x6b5124897c3c4 ในเลขฐานสิบเท่ากับ 1887939992208324)',
        title10: 'กระจายค่าที่สุ่มระหว่าง 0~1 โดยการหารด้วยค่าที่มากที่สุดใน 13 fs 0x6b5124897c3c4/0x100000000000000 เนื่องจากความสุ่มของค่าแฮช เราสามารถถือได้ว่าค่าที่สุ่มใด ๆ สามารถเปลี่ยนเป็นตัวเลขระหว่าง 0~1 ได้ 1887939992208324/4503599627370496 = 0.419206889692064',
        title11: 'สมมติว่าข้อได้เปรียบของเจ้ามือคือ 1%. คำนวณ 99/(1-X) โดยที่ X คือค่าที่คำนวณในขั้นตอนที่ 2 เมื่อ X เป็น 0 ผลลัพธ์คือ 99; เมื่อ X เป็น 1 ผลลัพธ์คืออนันต์; เมื่อ X เป็น 0.01 ผลลัพธ์คือ 100; เมื่อ X น้อยกว่า 0.01 ผลลัพธ์จะน้อยกว่า 100',
        title12: 'ข้อสรุป: การกระจายตัวเลขสุ่มโดยรวมจะอยู่ระหว่าง 99 ถึงอนันต์; และเมื่อการกระจายตัวเลขสุ่มอยู่ระหว่าง 0~0.01 ผลลัพธ์จะน้อยกว่า 100',
        title13: '99/(1-0.419206889692064) = 170.45656748150867 ทุกค่าที่น้อยกว่า 100 จะถูกตั้งค่าเป็น 100 กล่าวอีกนัยหนึ่ง ความน่าจะเป็นที่ 100 จะปรากฏคือ 1%. ปัดเศษตัวเลขและหารด้วย 100 จะได้ผลลัพธ์สุดท้าย 170/100 = 1.70',
        title14: 'ข้อสรุป: ค่าแฮชที่ใช้ในเกมจะถูกเรียงลำดับย้อนกลับ ดังนั้นด้วยอัลกอริธึม SHA256 คุณสามารถคำนวณค่าแฮชถัดไปจากค่าแฮชที่ใช้ในเกมก่อนหน้าได้ เนื่องจากคีย์และค่าแฮชมีความสัมพันธ์แบบหนึ่งต่อหนึ่ง เราสามารถสรุปได้ว่าหากค่าแฮชสามารถคำนวณค่าแฮชก่อนหน้าของมันได้ ค่าแฮชนี้ควรจะถูกสร้างขึ้นเมื่อรางวัลก่อนหน้าถูกประกาศ เช่นเดียวกับการสร้างห่วงโซ่ค่าแฮชทั้งหมดจะถูกสร้างขึ้นในตอนเริ่มต้น เมื่อถูกสร้างแล้วจะไม่สามารถเปลี่ยนแปลงได้ มิฉะนั้นจะไม่สามารถผ่านการตรวจสอบ SHA256 ได้ สำหรับรางวัล นี่เป็นเพียงเกมความน่าจะเป็น การชนเป็นสิ่งที่หลีกเลี่ยงไม่ได้ และผู้จัดงานไม่สามารถจัดการการตั้งค่าเกมใด ๆ ได้ ดังนั้น CRASH จึงโปร่งใสมากกว่าวิธีการพนันอื่น ๆ นี่คือพื้นฐานที่เราสร้างเกมของเรา',
        title15: 'การคำนวณง่าย:',
        title16: 'เมื่อค่าแฮช 13 หลักคือ 8000000000000 = 1.98, 0xb000000000 = 3.168, 0xc000000000 = 3.96, หมายถึงตำแหน่งแรกมากกว่า 8 ((16-8)/16≈0.5) ผลลัพธ์จะประมาณ 2x; เมื่อตำแหน่งแรกมากกว่า b ((16-11)/16≈0.3) ผลลัพธ์จะประมาณ 3x; เมื่อตำแหน่งแรกมากกว่า c ((16-12)/16≈0.25) ผลลัพธ์จะประมาณ 4x และอื่น ๆ',
        title17: 'เมื่อค่าแฮช 13 หลักคือ f000000000000 = 15.84, ff00000000000 = 253.44, fff000000',
        title18: 'หลักฐานความยุติธรรม',
        title19: 'ติดต่อเรา',
        title20: 'คำถามที่พบบ่อย',
        title21: 'สนับสนุนออนไลน์'
    },
    Treasure: {
        title: 'เชิญเพื่อนเพื่อรับกล่องสมบัติ',
        title1: 'ประวัติ',
        title2: 'รับแล้ว',
        title3: 'รับ',
        title4: 'ยังไม่รับ',
        title5: 'ระยะเวลากิจกรรม (UTC-3):',
        title6: '2024/04/26 00:00:00-2028/04/30 23:59:59 ข้อกำหนดกิจกรรม:',
        title7: 'เฉพาะผู้ใต้บังคับบัญชาและลูกน้องที่เพิ่งถูกรายงานใหม่เท่านั้นที่มีสิทธิ์เข้าร่วมกิจกรรม',
        title8: '1. เชิญเพื่อนเพื่อเปิดกล่องสมบัติ การเชิญเพื่อนจำนวนต่าง ๆ สามารถรับกล่องสมบัติที่สอดคล้องกันได้ สูงสุด 2 ล้าน บาท ยิ่งเชิญเพื่อนมาก รางวัลยิ่งมากขึ้น;',
        title9: '2. กิจกรรมนี้เป็นการมอบของขวัญพิเศษจากแพลตฟอร์ม สามารถรับรางวัลอื่น ๆ และค่าคอมมิชชั่นจากตัวแทนได้โดยตรง;',
        title10: '3. รางวัลจะสามารถรับได้เฉพาะในแอพ/iOS และ APP/Android เท่านั้น รางวัลที่หมดอายุจะถูกมอบให้โดยอัตโนมัติ;',
        title11: '4. รางวัลที่มอบในกิจกรรมนี้ (ยกเว้นหลัก) ต้องเป็นการเดิมพันที่มีประสิทธิภาพ โดยพื้นที่การเดิมพันจำกัดเฉพาะสล็อตแมชชีน: เกมทั้งหมด;',
        title12: '5. กิจกรรมนี้มีไว้สำหรับการดำเนินการตามปกติของเจ้าของบัญชีเท่านั้น ห้ามใช้วิธีการทางเทคนิคเช่น การเช่าบัญชี, การใช้โปรแกรมเสริม, หุ่นยนต์, การเดิมพันจากบัญชีที่แตกต่างกัน, การตั้งร้านซ้ำ, การอนุญา, API, ข้อตกลง, การละเมิดกฎ, การควบคุมกลุ่ม เป็นต้น มิฉะนั้นรางวัลจะถูกยกเลิกหรือหักออก, บัญชีอาจถูกระงับ หรือแม้กระทั่งถูกใส่ในรายชื่อดำ;',
        title13: '6. เพื่อหลีกเลี่ยงความแตกต่างในการอธิบายข้อความ แพลตฟอร์มขอสงวนสิทธิ์ในการตีความขั้นสุดท้ายของกิจกรรมนี้',
        title14: 'บันทึกการรับกล่องสมบัติ',
        title15: 'จำนวน',
        title16: 'เวลา'
    }
    
}
