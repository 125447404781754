<template>
    <div class="Playgroundbox">

        <div class="mybox ">
            <div class="title">{{chlist.name}} {{ $t('rechange.title') }}</div>
            <img class="imgs" :src="rechange.qr_code" alt="">
            <div class="title" style="color:#818E9D;">{{ $t('rechange.title1') }}<span style="color: #00FF86;">USDT-TRC20</span>{{ $t('rechange.title2') }}</div>
            <div class="address displays">
                <div class="info">{{rechange.address}}</div>
                <img @click="copy()" src="../assets/headimg/fuzhi.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { successNotification } from "../utils/el-ementui"
import { getwitrech, getpayment } from '@/api/index'
import heads from '@/components/heads.vue';
export default {
    name: 'Home',
    components: {
        heads
    },
    props:['id'],
    data() {
        return {
            chlist: {},
            rechange:{},
        }
    },
    async created() {
        await this.getpayment()
        // console.log(this.chlist)
        await this.getwitrech(this.chlist.url)
    },
    methods: {

        async getpayment() {
            const res = await getpayment()
            res.data.forEach((i,v)=>{
					if(this.id==i.type) {
						this.chlist = i
					}
				})
        },
        async getwitrech(url) {
            let data = {
                type: 1,
                money: 100
            }
            const res = await getwitrech(url, data)
          
            this.rechange = res.data
            
        },
        copy() {
            this.$copyText(this.rechange.address).then((res) => {
                successNotification(this.$t('ggtext.title'))
                }).catch(err => {
                successNotification(this.$t('ggtext.title1'))
                })
        }
    }
}
</script>

<style lang="scss" scoped>
img {
    vertical-align: middle;
}

::v-deep .el-progress-bar__outer {
    height: .5rem !important;
    margin-top: .5rem;
}

.Playgroundbox {

    min-height: 100vh;
    overflow-y: scroll;
    box-sizing: border-box;

    .mybox {
        font-size: .9rem;
        color: #818E9D;
        // padding: 2rem;
        padding: 0rem;
        text-align: center;

        .title {
            color: #fff;
            text-align: left;
            margin: 1.5rem 0rem;
            font-size: .9rem;
            font-weight: 500;

        }

        .imgs {
            margin-top: 1rem;
            width: 9rem;
            height: 9rem;
            background-color: #fff;
        }

        .address {
            width: 100%;
            background-color: #252c31;
            padding: 1rem;
            border-radius: .5rem;

            .info {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
            }

            img {
                width: .8619rem;
                height: .8681rem;
                margin-left: .5rem;
            }
        }
    }

}
</style>