import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import '@vant/touch-emulator';

import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import i18n from '@/lang/index'


Vue.config.productionTip = false


Vue.use(ElementUI);   // 这种方式引入了ElementUI中所有的组件


new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
