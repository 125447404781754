import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
import zh from './zh'
import en from './en'
import spa from './spa.js'
import al from './al.js'
import pt from './pt.js'
import ti from './tai.js'
import BN from './BN.js'
import ud from './ud.js'
Vue.use(VueI18n)

const messages = {
  zh,
  en,
  spa,
  al,
  pt,
  ti,
  BN,
  ud
}
const i18n = new VueI18n({
  messages,
  locale: localStorage.getItem('lang')=='zh-cn'? 'zh': localStorage.getItem('lang') ||'ti', // 默认显示语言 泰语
  silentTranslationWarn: true
})
export default i18n

