// 1、创建英文语言包对象
export default {
    heads: {
        title: 'Home',
        title1: 'Fair Proof',
        title2: 'Entertainment',
        title3: 'Promotion',
        title4: 'Treasure Chest',
        title5: 'Rewards',
        title6: 'Rules',
        title7: 'Betting Rebate',
        title8: 'Recharge Rebate',
        title9: 'Events',
        title10: 'Support',
        title11: 'Login',
        title12: 'Register',
        title13: 'Wallet',
        title14: 'Email',
        title15: 'Enter your email',
        title16: 'Password',
        title17: 'Enter your password',
        title18: 'Forgot password?',
        title19: 'No account?',
        title20: 'Register now',
        title21: 'Username',
        title22: 'Enter your username',
        title23: 'Transaction Password',
        title24: 'Enter your transaction password',
        title25: 'Invitation Code',
        title26: 'Enter your invitation code',
        title27: 'Already have an account?',
        title28: 'Log in',
        title29: 'Forgot Password',
        title30: 'Verification Code',
        title31: 'Enter your verification code',
        title32: 'New Password',
        title33: 'Enter your new password',
        title34: 'Confirm Password',
        title35: 'Enter your confirm password',
        title36: 'Change',
        title37: 'Recharge',
        title38: 'Withdraw',
        title39: 'Exchange',
        title40: 'Cryptocurrency',
        title41: 'Enter recharge amount',
        title42: 'Confirm',
        title43: 'Enter promo code or gift card code',
        title44: 'Recharge amount cannot be less than',
        title45: 'Email cannot be empty',
        title46: 'Password cannot be empty',
        title47: 'Transaction password cannot be empty',
        title48: 'Verification code cannot be empty',
        title49: 'New password cannot be empty',
        title50: 'Confirm password cannot be empty',
        title51: 'Passwords do not match',
        title52: 'Exchange code cannot be empty',
        title53: 'Username cannot be empty'
    },
    ggtext: {
        title: 'Copy successful',
        title1: 'Copy failed',
        title2: 'Loading...',
        title3: 'No more'
    },
    rechange: {
        title: 'Deposit',
        title1: 'Your',
        title2: 'Deposit Address'
    },
    withdraw: {
        title: 'Withdrawal',
        title1: 'Withdrawal Address',
        title2: 'Bank Card Number',
        title3: 'Amount',
        title4: 'Please enter transaction password',
        title5: 'Confirm',
        title6: 'Please bind USDT address first',
        title7: 'Please bind bank card number first',
        title8: 'Amount cannot be empty',
        title9: 'Transaction password cannot be empty'
    },
    user: {
        title: 'Bind Bank Information',
        title1: 'Country',
        title2: 'Address',
        title3: 'Bank Name',
        title4: 'Bank Account Number',
        title5: 'Name',
        title6: 'Email',
        title7: 'Confirm',
        title8: 'Edit',
        title9: 'Address cannot be empty',
        title10: 'Please select bank name',
        title11: 'Please enter card number',
        title12: 'Please enter name',
        title13: 'Please enter email',
        title14: 'Transaction',
        title15: 'All',
        title16: 'Deposit',
        title17: 'Withdrawal',
        title18: 'Amount',
        title19: 'Time',
        title20: 'Not Deposited',
        title21: 'Success',
        title22: 'Failure',
        title23: 'Pending Review',
        title24: 'Processing Payment',
        title25: 'Game Records',
        title26: 'Betting',
        title27: 'Profit',
        title28: 'Information',
        title29: 'Bind Bank Information',
        title30: 'Notification',
        title31: 'Statistics',
        title32: 'Change Transaction Password',
        title33: 'Transaction Records',
        title34: 'Game Records',
        title35: 'Logout',
        title36: 'Upload failed, please re-upload',
        title37: 'User Information',
        title38: 'Username',
        title39: 'Message Notification',
        title40: 'Introduction',
        title41: 'Details',
        title42: 'Statistics',
        title43: 'Games Played',
        title44: 'Bets Placed',
        title45: 'Net Profit',
        title46: 'Statistics for the past 24 hours',
        title47: 'Game',
        title48: 'Highest Bet',
        title49: 'Highest Win',
        title50: 'Verification Code',
        title51: 'New Password',
        title52: 'Confirm Password',
        title53: 'Email cannot be empty',
        title54: 'Verification code cannot be empty',
        title55: 'New password cannot be empty',
        title56: 'Confirm password cannot be empty',
        title57: 'Passwords do not match'
    },
    Activity: {
        title: 'Invitation Link',
        title1: 'Efficient Insiders',
        title2: 'People',
        title3: 'What is the number of players effectively increased?',
        title4: 'Cumulative Recharge of Subordinates',
        title5: '2 or more',
        title6: 'SuoordinateAcumwou to the ostas',  // (Seems like a typo or incorrect text; not translated)
        title7: '72 or more'
    },
    award: {
        title: 'Greater than the Failure Amount',
        title1: 'Gift Amount'
    },
    Betting: {
        title: 'Betting Transaction Reward',
        title1: 'First-Level Direct Referral',
        title2: 'Second-Level Direct Referral',
        title3: 'Third-Level Direct Referral'
    },
    home: {
        title: 'Welcome Back',
        title1: 'Enter search content',
        title2: 'Entertainment',
        title3: 'Visit Games',
        title4: 'Sports Betting',
        title5: 'Recent Wins',
        title6: 'Total Bets',
        title7: 'Live Betting',
        title8: 'High Roller',
        title9: 'Lucky Wins',
        title10: 'Games',
        title11: 'Users',
        title12: 'Time',
        title13: 'Amount'
    },
    games: {
        title: 'Official',
        title1: 'Trial',
        title2: 'Start Game',
        title3: 'No trial available for this game'
    },
    Playground: {
        title: 'Entertainment',
        title1: 'Enter search content',
        title2: 'Random Selection',
        title3: 'Provider',
        title4: 'Categorized by Conditions',
        title5: 'Back to Top'
    },
    Promotion: {
        title: 'Promotional Partners',
        title1: 'Create the code below to earn free rewards',
        title2: 'Invitation Link',
        title3: 'Direct Referrals',
        title4: 'First Deposit Users',
        title5: 'Deposit Users',
        title6: 'Total Team Recharge',
        title7: 'Total Team Amount',
        title8: 'Team Members',
        title9: 'Data Overview',
        title10: 'Team',
        title11: 'Other Members',
        title12: 'Turnover',
        title13: 'Total Turnover',
        title14: 'Direct Referral Turnover',
        title15: 'Other Turnover',
        title16: 'Commission',
        title17: 'Total Commission',
        title18: 'Direct Referral Commission',
        title19: 'Other Commission'
    },
    RechargeRebate: {
        title: 'Recharge Flow Reward',
        title1: 'First Deposit',
        title2: 'Reward'
    },
    rule: {
        title: 'Required Turnover',
        title1: 'Recharge Amount Requirement',
        title2: 'Weekly Reward',
        title3: 'Monthly Reward',
        title4: 'Member Upgrade Reward',
        title5: '*There are no limits on the number of withdrawals per day, daily withdrawal limits, or single withdrawal limits on this platform.'
    },
    support: {
        title: 'Fairness',
        title1: 'Is the game fair?',
        title2: 'We are a fair and impartial prediction and betting platform. Our goal is to eliminate all unfair factors and ensure players can play with confidence and enjoyment.',
        title3: 'We have generated 10 million hash values (the chain is verifiable), each hash value corresponds to a Crash multiplier.',
        title4: 'We release these 10 million numbers in reverse order, with each number corresponding to a round of the game (i.e., we have a total of 10 million rounds).',
        title5: 'In other words, the collision count for each round is pre-existing and not calculated after the game starts, so players can bet with confidence.',
        title6: 'Will the game be manipulated by the platform?',
        title7: 'The integrity check value is key to verifying whether there is official manipulation; the testing algorithm is as follows.',
        title8: 'Example: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: 'Take a random value within the range of 2^52, which is 16^13, i.e., a 13-digit hexadecimal number (because hash values are hexadecimal, so 2^52 === 16^13) 6b5124897c3c4 (0x6b5124897c3c4 in decimal equals 1887939992208324).',
        title10: 'Disperse the random value between 0 and 1, which is divided by the maximum value of 0x6b5124897c3c4 / 0x100000000000000. Considering the discrete randomness of hash values, we can assume any hash value can be converted to a random number between 0 and 1, 1887939992208324 / 4503599627370496 = 0.419206889692064.',
        title11: 'Let the house edge be 1%. Further calculate 99/(1-X), where X is the random value calculated in step 2. When X is 0, the result is 99; when X is 1, the result is positive infinity; when X is 0.01, the result is 100; when X is less than 0.01, the result is less than 100.',
        title12: 'Conclusion: The overall random number distribution ranges from 99 to positive infinity; when the random number distribution is between 0 and 0.01, the result is less than 100.',
        title13: '99/(1-0.419206889692064) = 170.45656748150867. All values less than 100 are set to 100. In other words, the probability of 100 appearing is 1%. Rounding the number and dividing by 100 gives the final result. 170/100 = 1.70',
        title14: "Conclusion: The hash values used in the game are in reverse order, so by using the SHA256 algorithm, the next hash value can be calculated from the previous one used in the game. Since the hash value's key and value are one-to-one correspondences, we can conclude that if a hash value can calculate its previous hash value, then this hash value should have already been generated during the previous prize announcement. Similarly, the entire hash chain was generated at the beginning and cannot be changed once generated; otherwise, it would fail SHA256 verification. As for the prize, it is merely a probability game, and crashes are inevitable. The official organizer cannot manipulate any game settings. Therefore, CRASH is more transparent than other gambling methods, which is the cornerstone of our game.",
        title15: 'Simple Calculation:',
        title16: 'When the 13-digit hash value is 8000000000000 = 1.98, 0xb000000000 = 3.168, 0xc000000000 = 3.96, where the first digit greater than 8 ((16-8)/16≈0.5), the result is approximately 2x; when the first digit is greater than b ((16-11)/16≈0.3), the result is approximately 3x; when the first digit is greater than c ((16-12)/16≈0.25), the result is approximately 4x, and so on.',
        title17: 'When the 13-digit hash value is f000000000000 = 15.84, ff00000000000 = 253.44, fff000000',
        title18: 'Fairness Proof',
        title19: 'Contact Us',
        title20: 'FAQ',
        title21: 'Online Support'
    },
    Treasure: {
        title: 'Invite Friends to Get Treasure Chest',
        title1: 'History',
        title2: 'Collected',
        title3: 'Collect',
        title4: 'Not Collected',
        title5: 'Event Time (UTC-3):',
        title6: '2024/04/26 00:00:00 - 2028/04/30 23:59:59',
        title7: 'Event Conditions:',
        title8: '1. Only newly referred subordinates and subordinates are eligible for the event.',
        title9: '2. Invite friends to open treasure chests. Inviting different numbers of friends can get corresponding treasure chests, with the highest amount being 2 million. The more friends you invite, the more rewards you get.',
        title10: '3. This event is an additional gift from the platform and can be enjoyed simultaneously with other rewards and agent commissions, i.e., directly enjoying various benefits.',
        title11: '4. The bonus from this event (excluding the main part) must be fulfilled with valid bets. The betting range is limited to slot machines: all games.',
        title12: '5. This event is limited to account holders operating normally. Prohibited methods include renting, using cheats, bots, betting with different accounts, mutual placements, arbitration, interfaces, agreements, violations, group control, and other technical means. Otherwise, rewards will be canceled or deducted, accounts frozen, or even blacklisted.',
        title13: '6. To avoid textual interpretation discrepancies, the platform reserves the final right of interpretation for this event.',
        title14: 'Treasure Chest Collection Records',
        title15: 'Quantity',
        title16: 'Time'
    }
    
}
