// 1、创建中文语言包对象
export default {
    heads: {
        title: "الصفحة الرئيسية",
        title1: "إثبات النزاهة",
        title2: "الترفيه",
        title3: "الترويج",
        title4: "الصندوق",
        title5: "المكافآت",
        title6: "القواعد",
        title7: "عائدات الرهان",
        title8: "عائدات الإيداع",
        title9: "الفعاليات",
        title10: "الدعم",
        title11: "تسجيل الدخول",
        title12: "التسجيل",
        title13: "المحفظة",
        title14: "البريد الإلكتروني",
        title15: "أدخل بريدك الإلكتروني",
        title16: "كلمة المرور",
        title17: "أدخل كلمة مرورك",
        title18: "نسيت كلمة المرور؟",
        title19: "ليس لديك حساب؟",
        title20: "سجل الآن",
        title21: "اسم المستخدم",
        title22: "أدخل اسم المستخدم الخاص بك",
        title23: "كلمة مرور المعاملة",
        title24: "أدخل كلمة مرور المعاملة الخاصة بك",
        title25: "رمز الدعوة",
        title26: "أدخل رمز الدعوة الخاص بك",
        title27: "هل لديك حساب بالفعل؟",
        title28: "تسجيل الدخول",
        title29: "نسيت كلمة المرور",
        title30: "رمز التحقق",
        title31: "أدخل رمز التحقق الخاص بك",
        title32: "كلمة مرور جديدة",
        title33: "أدخل كلمة مرورك الجديدة",
        title34: "تأكيد كلمة المرور",
        title35: "أدخل تأكيد كلمة المرور",
        title36: "تعديل",
        title37: "إيداع",
        title38: "سحب",
        title39: "تحويل",
        title40: "العملات المشفرة",
        title41: "أدخل مبلغ الإيداع",
        title42: "تأكيد",
        title43: "أدخل رمز الترويج أو رمز القسيمة",
        title44: "يجب ألا يكون الإيداع أقل من",
        title45: "لا يمكن أن يكون البريد الإلكتروني فارغًا",
        title46: "لا يمكن أن تكون كلمة المرور فارغة",
        title47: "لا يمكن أن تكون كلمة مرور المعاملة فارغة",
        title48: "لا يمكن أن يكون رمز التحقق فارغًا",
        title49: "لا يمكن أن تكون كلمة المرور الجديدة فارغة",
        title50: "لا يمكن أن تكون كلمة المرور المؤكدة فارغة",
        title51: "كلمتا المرور غير متطابقتين",
        title52: "رمز الاسترداد لا يمكن أن يكون فارغًا",
        title53: "لا يمكن أن يكون اسم المستخدم فارغًا"
    },
    ggtext: {
        title: "تم النسخ بنجاح",
        title1: "فشل النسخ",
        title2: "جارٍ التحميل...",
        title3: "لا توجد المزيد من البيانات"
    },
    rechange: {
        title: "الإيداع",
        title1: "عنوانك",
        title2: "للمبالغ"
    },
    withdraw: {
        title: "السحب",
        title1: "عنوان السحب",
        title2: "رقم بطاقة البنك",
        title3: "المبلغ",
        title4: "يرجى إدخال كلمة مرور المعاملة",
        title5: "تأكيد",
        title6: "يرجى ربط عنوان USDT أولاً",
        title7: "يرجى ربط رقم بطاقة البنك أولاً",
        title8: "لا يمكن أن يكون المبلغ فارغًا",
        title9: "لا يمكن أن تكون كلمة مرور المعاملة فارغة"
    },
    user: {
        title: "ربط معلومات البنك",
        title1: "الدولة",
        title2: "العنوان",
        title3: "اسم البنك",
        title4: "رقم بطاقة البنك",
        title5: "الاسم",
        title6: "البريد الإلكتروني",
        title7: "تأكيد",
        title8: "تعديل",
        title9: "لا يمكن أن يكون العنوان فارغًا",
        title10: "يرجى اختيار اسم البنك",
        title11: "يرجى إدخال رقم البطاقة",
        title12: "يرجى إدخال الاسم",
        title13: "يرجى إدخال البريد الإلكتروني",
        title14: "المعاملات",
        title15: "الكل",
        title16: "إيداع",
        title17: "سحب",
        title18: "العدد",
        title19: "الوقت",
        title20: "لم يتم الإيداع",
        title21: "نجاح",
        title22: "فشل",
        title23: "قيد المراجعة",
        title24: "قيد الدفع",
        title25: "سجل الألعاب",
        title26: "الرهانات",
        title27: "الأرباح",
        title28: "البيانات",
        title29: "ربط معلومات البنك",
        title30: "الإشعارات",
        title31: "البيانات الإحصائية",
        title32: "تغيير كلمة مرور المعاملة",
        title33: "سجل المعاملات",
        title34: "سجل الألعاب",
        title35: "تسجيل الخروج",
        title36: "فشل التحميل، يرجى إعادة التحميل",
        title37: "معلومات المستخدم",
        title38: "اسم المستخدم",
        title39: "إشعارات الرسائل",
        title40: "التعريف",
        title41: "التفاصيل",
        title42: "البيانات الإحصائية",
        title43: "الألعاب التي لعبتها",
        title44: "الرهانات",
        title45: "صافي الأرباح",
        title46: "البيانات الإحصائية لآخر 24 ساعة",
        title47: "اللعبة",
        title48: "أعلى رهان",
        title49: "أعلى ربح",
        title50: "رمز التحقق",
        title51: "كلمة مرور جديدة",
        title52: "تأكيد كلمة المرور",
        title53: "لا يمكن أن يكون البريد الإلكتروني فارغًا",
        title54: "لا يمكن أن يكون رمز التحقق فارغًا",
        title55: "لا يمكن أن تكون كلمة المرور الجديدة فارغة",
        title56: "لا يمكن أن تكون كلمة المرور المؤكدة فارغة",
        title57: "كلمتا المرور غير متطابقتين"
    },
    Activity: {
        title: "رابط الدعوة",
        title1: "الأفراد الفعالين",
        title2: "أشخاص",
        title3: "ما هو عدد اللاعبين الذين يمكن زيادتهم بشكل فعال؟",
        title4: "الإيداع الإجمالي للتابعين",
        title5: "2 أو أكثر كما هو مذكور",
        title6: "تنسيق الجمع إلى المواضع",
        title7: "72 أو أكثر"
    },
    award: {
        title: "أعلى من مبلغ الفشل",
        title1: "مبلغ الهدية"
    },
    Betting: {
        title: "مكافأة معاملات الرهان",
        title1: "الترويج المباشر من المستوى الأول",
        title2: "الترويج المباشر من المستوى الثاني",
        title3: "الترويج المباشر من المستوى الثالث"
    },
    home: {
        title: "مرحباً بعودتك",
        title1: "أدخل محتوى البحث",
        title2: "الترفيه",
        title3: "زيارة الألعاب",
        title4: "مراهنات رياضية",
        title5: "الانتصارات الأخيرة",
        title6: "إجمالي الرهان",
        title7: "الرهانات المباشرة",
        title8: "اللاعبون الكبار",
        title9: "فوز الحظ",
        title10: "الألعاب",
        title11: "المستخدم",
        title12: "الوقت",
        title13: "المبلغ"
    },
    games: {
        title: "رسمية",
        title1: "تجربة اللعب",
        title2: "ابدأ اللعبة",
        title3: "اللعبة الحالية غير متاحة للتجربة"
    },
    Playground: {
        title: "الترفيه",
        title1: "أدخل محتوى البحث",
        title2: "اختيار عشوائي",
        title3: "المورد",
        title4: "تصنيف حسب المعايير",
        title5: "العودة إلى الأعلى"
    },
    Promotion: {
        title: "شركاء الترويج",
        title1: "أنشئ الرمز أدناه لكسب المكافآت المجانية",
        title2: "رابط الدعوة",
        title3: "عدد الأشخاص المباشرين",
        title4: "عدد أولى الودائع",
        title5: "عدد الودائع",
        title6: "إجمالي إيداعات الفريق",
        title7: "إجمالي الفريق",
        title8: "أعضاء الفريق",
        title9: "نظرة عامة على البيانات",
        title10: "الفريق",
        title11: "أعضاء آخرون",
        title12: "التدفق",
        title13: "إجمالي المبيعات",
        title14: "تدفق الترويج المباشر",
        title15: "تدفق آخر",
        title16: "اللجنة",
        title17: "إجمالي العمولة",
        title18: "عمولة التوصيات المباشرة",
        title19: "عمولة أخرى"
    },
    RechargeRebate: {
        title: "مكافأة تدفق الإيداع",
        title1: "الإيداع الأول",
        title2: "المكافأة"
    },
    rule: {
        title: "يجب تحقيق حجم الأعمال",
        title1: "متطلبات مبلغ الإيداع",
        title2: "مكافآت أسبوعية",
        title3: "مكافآت شهرية",
        title4: "مكافآت ترقية الأعضاء",
        title5: "* لا يوجد حد لعدد مرات السحب اليومية، والحد اليومي للسحب، وحد السحب الفردي على هذه المنصة."
    },
    support: {
        title: "العدالة",
        title1: "هل اللعبة عادلة؟",
        title2: "نحن منصة توقعات عادلة وشفافة، وهدفنا هو القضاء على جميع العوامل غير العادلة لضمان أن يستمتع اللاعبون باللعبة بثقة وراحة.",
        title3: "لقد قمنا بتوليد 10 ملايين قيمة هاش (سلسلة التوليد قابلة للتحقق)، كل قيمة هاش تتوافق مع مضاعف Crash.",
        title4: "نقوم بنشر هذه الأرقام العشرة ملايين بترتيب عكسي، كل رقم يتوافق مع جولة من اللعبة (بمعنى أننا لدينا إجمالاً 10 ملايين جولة).",
        title5: "بعبارة أخرى، عدد الاصطدامات في كل جولة موجود مسبقاً، وليس محسوباً بعد بدء اللعبة، لذلك يمكن للاعبين المراهنة بثقة.",
        title6: "هل يمكن أن تتلاعب المنصة باللعبة؟",
        title7: "قيمة التحقق من النزاهة هي المفتاح للتحقق من عدم وجود تلاعب رسمي؛ الخوارزمية المستخدمة لاختبار ذلك كما يلي.",
        title8: "مثال: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231",
        title9: "خذ قيمة عشوائية ضمن نطاق 2^52، أي 16^13، وهو عدد سداسي عشري مكون من 13 رقمًا (نظرًا لأن القيم الهاش هي سداسية عشرية، لذا 2^52===16^13) 6b5124897c3c4 (0x6b5124897c3c4 في النظام العشري تساوي 1887939992208324).",
        title10: "نشر القيمة العشوائية بين 0 و1، أي قسمة على القيمة القصوى ضمن 13 fs 0x6b5124897c3c4/0x100000000000000. نظراً لعشوائية توزيع القيم الهاش، يمكننا اعتبار أي قيمة هاش يمكن تحويلها إلى رقم عشوائي بين 0 و1، 1887939992208324/4503599627370496 = 0.419206889692064.",
        title11: "افترض أن ميزة التاجر هي 1%. حساب 99/(1-X)، حيث X هو الرقم العشوائي المحسوب في الخطوة 2. عندما يكون X = 0، النتيجة هي 99؛ عندما يكون X = 1، النتيجة هي اللانهاية؛ عندما يكون X = 0.01، النتيجة هي 100؛ عندما يكون X أقل من 0.01، النتيجة أقل من 100.",
        title12: "الاستنتاج: توزيع الأرقام العشوائية هو من 99 إلى اللانهاية؛ وعندما يكون توزيع الأرقام العشوائية بين 0 و0.01، تكون النتيجة أقل من 100.",
        title13: "99/(1-0.419206889692064) = 170.45656748150867، جميع القيم الأقل من 100 ستضبط إلى 100. بعبارة أخرى، احتمالية ظهور 100 هي 1%. بعد تقريب الرقم وقسمته على 100، نحصل على النتيجة النهائية. 170/100 = 1.70",
        title14: "الاستنتاج: القيم الهاش المستخدمة في اللعبة هي في ترتيب عكسي، لذا من خلال خوارزمية SHA256، يمكن حساب القيمة الهاش التالية بناءً على القيمة الهاش السابقة المستخدمة في اللعبة. بما أن مفاتيح وقيم الهاش متطابقة بشكل فردي، يمكننا استنتاج أنه إذا كانت قيمة هاش يمكن حسابها بناءً على قيمة هاش سابقة، فيجب أن تكون تلك القيمة الهاش قد تم توليدها بالفعل عند نشر الجائزة السابقة. وبالمثل، يتم توليد سلسلة الهاش بالكامل في البداية، ولا يمكن تغييرها بعد التوليد، وإلا فلن يتم التحقق منها من خلال SHA256. أما بالنسبة للجوائز، فهي مجرد لعبة احتمالات، والانهيار أمر حتمي، ولا يمكن للجهة المنظمة الرسمية التلاعب بأي إعدادات للعبة. لذلك، تعتبر CRASH أكثر شفافية مقارنة بطرق المراهنات الأخرى، وهذا هو الأساس الذي تقوم عليه لعبتنا.",
        title15: "حساب بسيط:",
        title16: "عندما تكون قيمة الهاش المكونة من 13 رقماً هي 8000000000000 = 1.98، 0xb000000000 = 3.168، 0xc000000000 = 3.96، فإن الرقم الأول أكبر من 8 ((16-8)/16≈0.5)، تكون النتيجة تقريباً 2x؛ عندما يكون الرقم الأول أكبر من b ((16-11)/16≈0.3)، تكون النتيجة تقريباً 3x؛ عندما يكون الرقم الأول أكبر من c ((16-12)/16≈0.25)، تكون النتيجة تقريباً 4x، والبقية بالطريقة نفسها.",
        title17: "عندما تكون قيمة الهاش المكونة من 13 رقماً هي f000000000000 = 15.84، ff00000000000 = 253.44، fff000000",
        title18: "دليل العدالة",
        title19: "اتصل بنا",
        title20: "الأسئلة الشائعة",
        title21: "الدعم عبر الإنترنت"
    },
    Treasure: {
        title: "ادعُ الأصدقاء للحصول على الصندوق",
        title1: "التاريخ",
        title2: "تم استلامه",
        title3: "استلام",
        title4: "لم يتم استلامه",
        title5: "مدة النشاط (UTC-3):",
        title6: "2024/04/26 00:00:00 - 2028/04/30 23:59:59",
        title7: "شروط النشاط:",
        title8: "1. ادعُ الأصدقاء لفتح الصندوق، يمكنك الحصول على صندوق مطابق لعدد الأشخاص المدعوين، أعلى مبلغ 2 مليون، كلما زاد عدد الأصدقاء المدعوين، زادت المكافآت.",
        title9: "2. هذا النشاط هو مكافأة إضافية من المنصة، ويمكن الاستمتاع بها بجانب المكافآت الأخرى وعمولات الوكلاء، أي الاستمتاع بجميع المتع.",
        title10: "3. الجوائز مقتصرة على الاستلام اليدوي عبر تطبيقات iOS وAndroid، والجوائز المنتهية صلاحيتها سيتم منحها تلقائيًا.",
        title11: "4. الجوائز المقدمة في هذا النشاط (باستثناء الجوائز الرئيسية) يجب تحويلها إلى رهانات فعالة، نطاق الرهان محدود لآلات القمار: جميع الألعاب.",
        title12: "5. هذا النشاط مخصص فقط لحاملي الحسابات لتشغيلها بشكل طبيعي، يحظر استخدام أساليب تقنية مثل الإيجار، استخدام برامج غير رسمية، الروبوتات، المراهنة عبر حسابات مختلفة، وضع أكشاك لبعضهم البعض، التحكيم، الواجهات، الاتفاقيات، الانتهاكات، التحكم الجماعي، وما إلى ذلك، وإلا سيتم إلغاء أو خصم المكافآت، تجميد الحساب أو حتى إدراجه في القائمة السوداء.",
        title13: "6. لتجنب الخلافات حول تفسير النصوص، تحتفظ المنصة بالحق النهائي في تفسير هذا النشاط.",
        title14: "سجل استلام الصندوق",
        title15: "العدد",
        title16: "الوقت"
    }
    
}
