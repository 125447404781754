import { Notification } from 'element-ui';



/**
 *@functionName: 通知的封装(成功和警告)
 *@params1: title
 *@params2: message
 *@params3: type  enum[success,warning]
 *@description:
 *@author: yk 
 *@date: 2023-11-30 10:18:58
 *@version: V1.0.0
 */

export function successNotification(message) {
    Notification({
        message: message,
        position: 'bottom-left',
        showClose: false,
        customClass: 'eleclass',
        duration:2000
    });
}

/**
 *@functionName: 通知的封装(错误)
 *@params1: title
 *@params2: message
 *@description:
 *@author: yk 
 *@date: 2023-11-30 10:18:58
 *@version: V1.0.0
 */
// export function errNotification(title, message) {
//     Notification.error({
//         title: title,
//         message: message,
//         position: 'bottom-right'
//     });
// }

/**
 *@functionName: 通知的封装(消息)
 *@params1: title
 *@params2: message
 *@description:
 *@author: yk 
 *@date: 2023-11-30 10:18:58
 *@version: V1.0.0
 */
// export function infoNotification(title, message) {
//     Notification.info({
//         title: title,
//         message: message,
//         position: 'bottom-right'
//     });
// }
