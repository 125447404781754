import request from '@/utils/require'

export function getban () { //轮播
	return request.post("api/index/getban")
}

export function login (data) { //登录
	return request.post("api/user/login",data)
}

export function loggame_indexin () { // 首页游戏
	return request.post("api/index/game_index")
}

export function getuserinfo() { // 首页游戏
	return request.post("api/user/getUserinfo")
}

export function get_fool () { // 消息
	return request.post("api/index/get_fool")
}

export function getregister (data) { // 注册
	return request.post("api/user/register",data)  
}

export function logout () { // 退出   
	return request.post("api/user/logout")
}

export function getrechange (data) { // 充值   
	return request.post("api/index/Withdraw_recharge",data)
}

export function getcoupon (data) { // 兑换优惠券  
	return request.post("api/index/set_code",data)
}
   
export function getgameindex (data) { // 游戏列表  
	return request.post("api/index/game_index",data)
}

export function getgamedetai (data) { // 游戏列表详情 
	return request.post("api/index/game_detai",data)
}

export function getupload (data) { // 上传feil文件
	return request.post("api/common/upload",data)
}

export function getprofile (data) { // 上传图片
	return request.post("api/user/profile",data)
}  

export function getstatistics (data) { // 统计数据
	return request.post("api/index/data",data)
}  

export function getpromotion (data) { // 推广
	return request.post("api/index/get_links",data)
}  

export function getlanguage (data) { // 获取语言
	return request.post("api/common/get_language",data)
}  

export function gethistorylist (data) { // 获取充值提现记录
	return request.post("api/index/wit_rech_list",data)
}  

export function getwitrech (url,data) { // 充值和提现接口
	return request.post(url,data)
}  

export function getgamels (data) { // 游戏记录
	return request.post("api/index/find_bets",data)
} 

export function getgamedata (data) { // 游戏播报
	return request.post("api/index/game_data ",data)
} 

export function getsend (data) { // 发送验证码
	return request.post("api/ems/send ",data)
} 

export function getchangepwd (data) { // 修改密码
	return request.post("api/user/change_pwd ",data)
} 

export function getvips (data) { // 会员
	return request.post("api/index/get_vips",data)
} 

export function getcoin (data) { // 获取币种列表
	return request.post("api/index/get_coin",data)
} 

export function getpayment (data) { // 获取充值
	return request.post("api/index/getpayment",data)
}

export function getsetting (data) { // 宝箱
	return request.post("api/index/get_recharge_setting",data)
}

export function getboxpic (data) { //  宝箱领取
	return request.post("api/index/get_box_prize",data)
}

export function getboxlog (data) { //  宝箱领取记录
	return request.post("api/index/get_box_log",data)
}

export function getaddbank (data) { //  绑定银行
	return request.post("api/index/add_bank",data)
}

export function getmybank (data) { //  绑定银行
	return request.post("api/index/my_bank",data)
}

export function getrechlist (data) { //  充值返利
	return request.post("api/index/get_rechlist",data)
}

export function getteam_reward (data) { //  充值返利
	return request.post("api/index/get_team_reward",data)
}

export function getgametype (data) { // 游戏类型
	return request.post("api/index/get_game_category",data)
}

export function getgamePlat (data) { // 游戏类型
	return request.post("api/index/get_gamePlat",data)
}

export function getgamesort (data) { // 游戏排序方式
	return request.post("api/index/get_game_sort",data)
}

export function getlikegame (data) { // 收藏游戏
	return request.post("api/index/like_game",data)
}

export function getqxlikegame (data) { // 取消收藏
	return request.post("api/index/c_like_game",data)
}

export function getbankname (data) { // 获取银行名称
	return request.post("api/index/bank_lists",data)
}

export function getleftgeam (data) { // 类型游戏
	return request.post("api/index/get_game_left",data)
}

export function getjiangli (data) { // 类型游戏
	return request.post("api/index/get_lost_rebate",data)
}



