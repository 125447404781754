// 1、创建中文语言包对象
export default {
    heads: {
        title: 'Página Inicial',
        title1: 'Prova de Justiça',
        title2: 'Cassino',
        title3: 'Promoção',
        title4: 'Caixa',
        title5: 'Recompensas',
        title6: 'Regras',
        title7: 'Rebates de Apostas',
        title8: 'Rebates de Depósito',
        title9: 'Atividades',
        title10: 'Suporte',
        title11: 'Entrar',
        title12: 'Registrar',
        title13: 'Carteira',
        title14: 'E-mail',
        title15: 'Digite seu e-mail',
        title16: 'Senha',
        title17: 'Digite sua senha',
        title18: 'Esqueceu a senha?',
        title19: 'Não tem uma conta? ',
        title20: 'Registrar',
        title21: 'Nome de usuário',
        title22: 'Digite seu nome de usuário',
        title23: 'Senha de transação',
        title24: 'Digite sua senha de transação',
        title25: 'Código de convite',
        title26: 'Digite seu código de convite',
        title27: 'Já tem uma conta?',
        title28: 'Entrar',
        title29: 'Esqueci a senha',
        title30: 'Código de verificação',
        title31: 'Digite seu código de verificação',
        title32: 'Nova senha',
        title33: 'Digite sua nova senha',
        title34: 'Confirmar senha',
        title35: 'Digite a confirmação da senha',
        title36: 'Alterar',
        title37: 'Depósito',
        title38: 'Saque',
        title39: 'Troca',
        title40: 'Criptomoeda',
        title41: 'Digite o valor do depósito',
        title42: 'Confirmar',
        title43: 'Digite o código promocional ou código do vale-presente',
        title44: 'O depósito não pode ser menor que',
        title45: 'O e-mail não pode estar vazio',
        title46: 'A senha não pode estar vazia',
        title47: 'A senha de transação não pode estar vazia',
        title48: 'O código de verificação não pode estar vazio',
        title49: 'A nova senha não pode estar vazia',
        title50: 'A confirmação da senha não pode estar vazia',
        title51: 'As senhas não coincidem',
        title52: 'O código de troca não pode estar vazio',
        title53: 'O nome de usuário não pode estar vazio'
    },
    ggtext: {
        title: 'Copiado com sucesso',
        title1: 'Falha ao copiar',
        title2: 'Carregando...',
        title3: 'Não há mais itens'
    },
    rechange: {
        title: 'Depósito',
        title1: 'Seu',
        title2: 'Endereço de Depósito'
    },
    withdraw: {
        title: 'Saque',
        title1: 'Endereço de Saque',
        title2: 'Número da Conta Bancária',
        title3: 'Valor',
        title4: 'Digite a senha de transação',
        title5: 'Confirmar',
        title6: 'Primeiro, vincule o endereço USDT',
        title7: 'Primeiro, vincule o número da conta bancária',
        title8: 'O valor não pode estar vazio',
        title9: 'A senha de transação não pode estar vazia'
    },
    user: {
        title: 'Vincular Informações Bancárias',
        title1: 'País',
        title2: 'Endereço',
        title3: 'Nome do Banco',
        title4: 'Número da Conta Bancária',
        title5: 'Nome',
        title6: 'E-mail',
        title7: 'Confirmar',
        title8: 'Alterar',
        title9: 'O endereço não pode estar vazio',
        title10: 'Selecione o nome do banco',
        title11: 'Digite o número do cartão',
        title12: 'Digite o nome',
        title13: 'Digite o e-mail',
        title14: 'Transações',
        title15: 'Todos',
        title16: 'Depósitos',
        title17: 'Saques',
        title18: 'Quantia',
        title19: 'Hora',
        title20: 'Não Depositado',
        title21: 'Bem-sucedido',
        title22: 'Falhou',
        title23: 'Pendente de Aprovação',
        title24: 'Processando Pagamento',
        title25: 'Histórico de Jogos',
        title26: 'Apostas',
        title27: 'Lucro',
        title28: 'Informações',
        title29: 'Vincular Informações Bancárias',
        title30: 'Notificações',
        title31: 'Estatísticas',
        title32: 'Alterar Senha de Transação',
        title33: 'Histórico de Transações',
        title34: 'Histórico de Jogos',
        title35: 'Sair',
        title36: 'Falha ao carregar, tente novamente',
        title37: 'Informações do Usuário',
        title38: 'Nome de Usuário',
        title39: 'Notificações de Mensagens',
        title40: 'Introdução',
        title41: 'Detalhes',
        title42: 'Estatísticas',
        title43: 'Jogos Jogados',
        title44: 'Apostas Realizadas',
        title45: 'Lucro Líquido',
        title46: 'Estatísticas das Últimas 24 Horas',
        title47: 'Jogo',
        title48: 'Maior Aposta',
        title49: 'Maior Retorno',
        title50: 'Código de Verificação',
        title51: 'Nova Senha',
        title52: 'Confirmar Senha',
        title53: 'O e-mail não pode estar vazio',
        title54: 'O código de verificação não pode estar vazio',
        title55: 'A nova senha não pode estar vazia',
        title56: 'A confirmação da senha não pode estar vazia',
        title57: 'As senhas não coincidem'
    },
    Activity: {
        title: 'Link de Convite',
        title1: 'Pessoal Interno Eficiente',
        title2: 'Pessoas',
        title3: 'Qual é o número de jogadores efetivamente aumentados?',
        title4: 'Total de Recarga dos Subordinados',
        title5: '2 ou mais mencionados',
        title6: 'SuoordinateAcumwou to the ostas',
        title7: '72 ou mais'
    },
    award: {
        title: 'Maior que o Valor Perdido',
        title1: 'Valor do Presente'
    },
    Betting: {
        title: 'Recompensa de Transação de Apostas',
        title1: 'Primeiro Nível Direto',
        title2: 'Segundo Nível Direto',
        title3: 'Terceiro Nível Direto'
    },
    home: {
        title: 'Bem-vindo de Volta',
        title1: 'Digite o conteúdo da pesquisa',
        title2: 'Cassino',
        title3: 'Acessar Jogos',
        title4: 'Apostas Esportivas',
        title5: 'Vitórias Recentes',
        title6: 'Total Apostado',
        title7: 'Apostas ao Vivo',
        title8: 'Grandes Apostadores',
        title9: 'Vitória da Sorte',
        title10: 'Jogos',
        title11: 'Usuário',
        title12: 'Tempo',
        title13: 'Valor'
    },
    games: {
        title: 'Oficial',
        title1: 'Jogar Demonstração',
        title2: 'Começar Jogo',
        title3: 'Este jogo não tem demonstração'
    },
    Playground: {
        title: 'Cassino',
        title1: 'Digite o conteúdo da pesquisa',
        title2: 'Escolha Aleatória',
        title3: 'Fornecedor',
        title4: 'Classificar por Condições',
        title5: 'Voltar ao Topo'
    },
    Promotion: {
        title: 'Parceiros de Promoção',
        title1: 'Crie o código abaixo para ganhar recompensas gratuitas',
        title2: 'Link de Convite',
        title3: 'Número de Recrutamentos Diretos',
        title4: 'Número de Depósitos Iniciais',
        title5: 'Número de Depósitos',
        title6: 'Total de Recargas da Equipe',
        title7: 'Valor Total da Equipe',
        title8: 'Membros da Equipe',
        title9: 'Visão Geral dos Dados',
        title10: 'Equipe',
        title11: 'Outros Membros',
        title12: 'Fluxo',
        title13: 'Volume Total de Negócios',
        title14: 'Fluxo Direto de Recrutamento',
        title15: 'Outros Fluxos',
        title16: 'Comissão',
        title17: 'Comissão Total',
        title18: 'Comissão de Recrutamento Direto',
        title19: 'Outras Comissões'
    },
    RechargeRebate: {
        title: 'Recompensa de Fluxo de Depósito',
        title1: 'Depósito Inicial',
        title2: 'Remuneração'
    },
    rule: {
        title: 'Requisitos de Volume de Negócios',
        title1: 'Requisitos de Valor de Depósito',
        title2: 'Recompensas Semanais',
        title3: 'Recompensas Mensais',
        title4: 'Recompensas de Upgrade de Membro',
        title5: '* O número de saques diários, o limite diário de saques e o limite por saque não têm restrições no nosso site.'
    },
    support: {
        title: 'Justiça',
        title1: 'O jogo é justo?',
        title2: 'Somos uma plataforma de previsão e apostas justa e imparcial. Nosso objetivo é eliminar todos os fatores injustos, permitindo que os jogadores joguem com confiança e se divirtam.',
        title3: 'Geramos um total de 10 milhões de valores de hash (a cadeia gerada é verificável), cada valor de hash corresponde a um multiplicador de Crash.',
        title4: 'Publicamos esses 10 milhões de números em ordem inversa, cada número corresponde a uma rodada do jogo (ou seja, temos um total de 10 milhões de rodadas).',
        title5: 'Em outras palavras, o número de colisões de cada rodada já está pré-existente e não é calculado após o início do jogo, portanto, os jogadores podem apostar com confiança.',
        title6: 'O jogo pode ser manipulado pela plataforma?',
        title7: 'O valor de verificação de integridade é a chave para verificar se há manipulação oficial; o algoritmo de teste é o seguinte.',
        title8: 'Exemplo: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: 'Pegue um valor aleatório no intervalo de 2^52, ou seja, 16^13, ou um número hexadecimal de 13 dígitos (porque o valor de hash é hexadecimal, então 2^52 === 16^13) 6b5124897c3c4 (0x6b5124897c3c4 em decimal é igual a 1887939992208324).',
        title10: 'Distribua o valor aleatório entre 0 e 1, dividindo pelo valor máximo em 13 fs 0x6b5124897c3c4/0x100000000000000. Considerando a aleatoriedade discreta do hash, podemos considerar que qualquer valor de hash pode ser convertido em um número aleatório entre 0 e 1, 1887939992208324/4503599627370496 = 0.419206889692064.',
        title11: 'Se a vantagem da casa for de 1%, calcule 99/(1-X), onde X é o valor aleatório calculado no passo 2. Quando X é 0, o resultado é 99; quando X é 1, o resultado é infinito; quando X é 0,01, o resultado é 100; quando X é menor que 0,01, o resultado é menor que 100.',
        title12: 'Conclusão: A distribuição geral dos números aleatórios varia de 99 a infinito; e quando a distribuição de números aleatórios é de 0 a 0,01, o resultado é menor que 100.',
        title13: '99/(1-0.419206889692064) = 170.45656748150867 Todos os valores abaixo de 100 serão ajustados para 100. Em outras palavras, a probabilidade de ocorrer 100 é de 1%. Arredondar o número e dividir por 100 resulta no resultado final. 170/100 = 1.70',
        title14: 'Conclusão: O valor de hash usado no jogo é na ordem inversa, portanto, através do algoritmo SHA256, pode-se calcular o próximo valor de hash a partir do valor de hash anterior usado no jogo. Como o hash é uma correspondência única de chaves e valores, podemos concluir que se um valor de hash pode calcular o valor de hash anterior, então esse valor de hash deve ter sido gerado no momento da publicação do prêmio anterior. Da mesma forma, toda a cadeia de hashes é gerada no início e, uma vez gerada, não pode ser alterada, caso contrário, não passaria pela verificação SHA256. Quanto aos prêmios, eles são apenas um jogo de probabilidade, e os crashes são inevitáveis. O organizador oficial não pode manipular nenhuma configuração do jogo. Portanto, CRASH é mais transparente do que outros métodos de jogo, e isso é a base sobre a qual nosso jogo foi construído.',
        title15: 'Cálculo Simples:',
        title16: 'Quando o valor de hash de 13 dígitos é 8000000000000 = 1.98, 0xb000000000 = 3.168, 0xc000000000 = 3.96, ou seja, o primeiro dígito maior que 8 ((16-8)/16≈0.5), o resultado é aproximadamente 2x; quando o primeiro dígito é maior que b ((16-11)/16≈0.3), o resultado é aproximadamente 3x; quando o primeiro dígito é maior que c ((16-12)/16≈0.25), o resultado é aproximadamente 4x, e assim por diante.',
        title17: 'Quando o valor de hash de 13 dígitos é f000000000000 = 15.84, ff00000000000 = 253.44, fff000000',
        title18: 'Prova de Justiça',
        title19: 'Entre em Contato',
        title20: 'Perguntas Frequentes',
        title21: 'Suporte Online'
    },
    Treasure: {
        title: 'Convide Amigos para Ganhar Caixas',
        title1: 'Histórico',
        title2: 'Já Recebido',
        title3: 'Receber',
        title4: 'Não Recebido',
        title5: 'Período da Atividade (UTC-3):',
        title6: '2024/04/26 00:00:00-2028/04/30 23:59:59',
        title7: 'Condições da Atividade:',
        title8: '1. Convide amigos para abrir caixas, e você receberá caixas correspondentes ao número de amigos convidados, com um valor máximo de 2 milhões. Quanto mais amigos você convidar, mais recompensas você receberá;',
        title9: '2. Esta atividade é um presente adicional da plataforma e pode ser combinada com outras recompensas e comissões de agentes, ou seja, você pode desfrutar de várias vantagens;',
        title10: '3. O prêmio só pode ser resgatado manualmente no app/iOS, APP/Android. Prêmios expirados serão distribuídos automaticamente;',
        title11: '4. Os prêmios distribuídos nesta atividade (exceto principais) devem ser convertidos em apostas válidas, e o escopo de apostas é restrito a caça-níqueis: todos os jogos;',
        title12: '5. Esta atividade é limitada aos titulares de contas que operam normalmente. É proibido usar aluguel, cheats, bots, apostas em diferentes contas, truques, arbitragens, interfaces, acordos, violação de regras, controle de grupo e outros métodos técnicos para participar. Caso contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada e até mesmo listada na lista negra;',
        title13: '6. Para evitar divergências na interpretação textual, a plataforma se reserva o direito de interpretar esta atividade.',
        title14: 'Registro de Recebimento de Caixas',
        title15: 'Quantidade',
        title16: 'Tempo'
    }
    
}
