import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/heads',
    name: 'heads',
    component: () => import('../components/heads.vue')
  },
  {
    path: '/Playground',
    name: 'Playground',
    component: () => import('../views/Playground.vue')
  },
  {
    path: '/Promotion',
    name: 'Promotion',
    component: () => import('../views/Promotion.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/Activity',
    name: 'Activity',
    component: () => import('../views/Activity.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/support.vue')
  },
  {
    path: '/games',
    name: 'games',
    component: () => import('../views/games.vue')
  },
  // {
  //   path: '/GameRecords',
  //   name: 'GameRecords',
  //   component: () => import('../views/user/GameRecords.vue')
  // }
  // {
  //   path: '/info',
  //   name: 'user',
  //   component: () => import('../views/user/info.vue')
  // },
  // {
  //   path: '/bank',
  //   name: 'bank',
  //   component: () => import('../views/user/bank.vue')
  // },
  // {
  //   path: '/Small',
  //   name: 'Small',
  //   component: () => import('../views/user/Small.vue')
  // },
  // {
  //   path: '/statistics',
  //   name: 'statistics',
  //   component: () => import('../views/user/statistics.vue')
  // },
  // {
  //   path: '/trade',
  //   name: 'trade',
  //   component: () => import('../views/user/trade.vue')
  // },
   // {
  //   path: '/funds',
  //   name: 'funds',
  //   component: () => import('../views/user/funds.vue')
  // }
   // {
  //   path: '/GameRecords',
  //   name: 'GameRecords',
  //   component: () => import('../views/user/GameRecords.vue')
  // }
] 

//核心代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
