<template>
  <div class="hello">
    <div class="headbox">
      <div class="centerbox">
        <div class="left" @click="$router.push('/')">
          <img src="../assets/logo.png" alt="">
          <div>PGgame</div>
        </div>
        <div class="center">
          <div class=" top">
            <!-- <div>会员</div> -->
            <div @click="$router.push('/support')">{{ $t('heads.title1') }}</div>
            <!-- <div>博客</div> -->
          </div>
          <div class="display bot">
            <div :class="{ 'xzdiv': index == 1 }" @click="changeindex(1)">{{ $t('heads.title') }}</div>
            <div :class="{ 'xzdiv': index == 2 }" @click="changeindex(2)">{{ $t('heads.title2') }}</div>
            <div :class="{ 'xzdiv': index == 6 }" @click="changeindex(6)">{{ $t('heads.title3') }}</div>


            <el-popover placement="bottom" v-model="visible" :visible-arrow="false" popper-class="mypopover"
              trigger="hover">

              <div class="itemboxs">

                <div class=" baoxi" @click="toactive(1)">
                  <img class="baoxiang" src="../assets/baxi.png" alt="">
                  <div>
                    {{ $t('heads.title4') }}
                  </div>
                </div>
                <div class=" baoxi" @click="toactive(2)">{{ $t('heads.title5') }}</div>
                <div class=" baoxi" @click="toactive(3)">{{ $t('heads.title6') }}</div>
                <div class=" baoxi" @click="toactive(4)">{{ $t('heads.title7') }}</div>
                <div class=" baoxi" @click="toactive(5)">{{ $t('heads.title8') }}</div>

              </div>

              <div slot="reference" class="displays xzbox">
                <div :class="{ 'xzdiv': index == 7 }">{{ $t('heads.title9') }}</div>
              </div>
            </el-popover>

            <div :class="{ 'xzdiv': index == 5 }" @click="changeindex(5)">{{ $t('heads.title10') }}</div>
          </div>
        </div>
      </div>
      <div class="right" v-if="value == 2">
        <div @click="showlogin()">{{ $t('heads.title11') }}</div>
        <div @click="showreg()">{{ $t('heads.title12') }}</div>
        <img src="../assets/headimg/xiaoxi.png" alt="">
      </div>
      <div class="rights display" v-else>

        <el-popover placement="bottom" v-model="visibless" :visible-arrow="false" popper-class="myredio" trigger="click">

          <van-radio-group v-model="langradio">
            <van-radio :name="v" checked-color="#00FF86" v-for="(i, v) in languagelist" @click="changelang(i)">{{ i.name
              }}</van-radio>
          </van-radio-group>

          <div slot="reference" class="displays xzbox">
            <div>
              <img style="width: 1.5rem;" src="../assets/yuyan.png" alt="">
            </div>
          </div>
        </el-popover>



        <el-popover placement="bottom" v-model="visibles" :visible-arrow="false" popper-class="myredio" trigger="click">

          <van-radio-group v-model="radio">
            <van-radio :name="v" checked-color="#00FF86" v-for="(i, v) in bilist" @click="xzradio(i)">{{ i.country
              }}</van-radio>
          </van-radio-group>

          <div slot="reference" class="displays xzbox">

            <div class="well displays" v-if="bilist[0]">
              <div class="display left">
                <img class="bi" :src="biimg ? biimg : bilist[0].ico" alt="">
                <div>$ {{ bipic ? bipic : bilist[0].money }}</div>
              </div>
              <img src="../assets/headimg/xiala.png" alt="">
            </div>
          </div>
        </el-popover>

        <div class="qianbao" @click="funds()">{{ $t('heads.title13') }}</div>
        <img @click="$router.push('/user')" class="active" :src="newuser.avatar" alt=""> <!-- 头像地方 -->
        <img @click="tokefu()" src="../assets/headimg/xiaoxi.png" alt="">
      </div>
    </div>
    <!-- 登录 -->
    <van-popup v-model="show" closeable :close-on-click-overlay="false"
      style="border-radius: 1.3rem;background-color: #131A22;">
      <div class="loginbox display">
        <img class="leftimg" src="../assets/headimg/logobj.png" alt="">
        <div class="login">
          <div class="lotitle">
            <img src="../assets/logo.png" alt="">
            <div>{{ $t('heads.title11') }}</div>
          </div>
          <div class="name">{{ $t('heads.title14') }}</div>
          <input type="text" :placeholder="$t('heads.title15')" v-model="login.email">
          <div class="name">{{ $t('heads.title16') }}</div>
          <input type="password" :placeholder="$t('heads.title17')" v-model="login.password">
          <div class="name" @click="tofpshow()">{{ $t('heads.title18') }}</div>

          <div class="btn" @click="tologin()">{{ $t('heads.title11') }}</div>

          <div class="toreg">{{ $t('heads.title19') }}<span @click="showreg()">{{ $t('heads.title20') }}</span></div>
        </div>
      </div>
    </van-popup>
    <!-- 注册 -->
    <van-popup v-model="regshow" closeable :close-on-click-overlay="false"
      style="border-radius: 1.3rem;background-color: #131A22;">
      <div class="loginbox display">
        <img class="leftimg" src="../assets/headimg/logobj.png" alt="">
        <div class="reg">
          <div class="lotitle">
            <img src="../assets/logo.png" alt="">
            <div>{{ $t('heads.title12') }}</div>
          </div>
          <div class="name">{{ $t('heads.title21') }}</div>
          <input type="text" :placeholder="$t('heads.title22')" v-model="reg.name">
          <div class="name">{{ $t('heads.title14') }}</div>
          <input type="text" :placeholder="$t('heads.title15')" v-model="reg.email">
          <div class="name">{{ $t('heads.title16') }}</div>
          <input type="password" :placeholder="$t('heads.title17')" v-model="reg.password">
          <div class="name">{{ $t('heads.title23') }}</div>
          <input type="password" :placeholder="$t('heads.title24')" v-model="reg.jypassword">
          <div class="name">{{ $t('heads.title25') }}</div>
          <input type="text" :placeholder="$t('heads.title26')" v-model="reg.code">

          <div class="btn" @click="getregister()">{{ $t('heads.title12') }}</div>

          <div class="toreg">{{ $t('heads.title27') }} <span @click="showlogin()">{{ $t('heads.title28') }}</span></div>
        </div>
      </div>
    </van-popup>
    <!-- 忘记密码 -->
    <van-popup v-model="fpshow" closeable :close-on-click-overlay="false"
      style="border-radius: 1.3rem;background-color: #131A22;">
      <div class="loginbox display">
        <img class="leftimg" src="../assets/headimg/logobj.png" alt="">
        <div class="reg">
          <div class="lotitle">
            <img src="../assets/logo.png" alt="">
            <div>{{ $t('heads.title29') }}</div>
          </div>
          <div class="name">{{ $t('heads.title14') }}</div>
          <input type="text" :placeholder="$t('heads.title15')" v-model="wj.email">
          <div class="name">{{ $t('heads.title30') }}</div>
          <div class="sendcode">
            <input type="text" :placeholder="$t('heads.title31')" v-model="wj.code">
            <div class="codes" @click="getcode()" id="codes">code</div>
          </div>
          <div class="name">{{ $t('heads.title32') }}</div>
          <input type="password" :placeholder="$t('heads.title33')" v-model="wj.oldpassword">
          <div class="name">{{ $t('heads.title34') }}</div>
          <input type="password" :placeholder="$t('heads.title35')" v-model="wj.newpassword">


          <div class="btn" style="margin: 3rem 0rem;" @click="changepass()">{{ $t('heads.title36') }}</div>

        </div>
      </div>
    </van-popup>
    <!-- 充值和提现 -->
    <van-popup v-model="fundsshow" closeable :close-on-click-overlay="false"
      style="border-radius: 1.3rem;background-color: #131A22;">
      <div class="loginbox display">
        <img class="leftimg" src="../assets/headimg/logobj.png" alt="">
        <div class="reg">
          <div class="topjy">
            <div class="titlejy" :class="{ 'green': rhindex == 1 }" @click="rechangeindex(1)">{{ $t('heads.title37') }}
            </div>
            <div class="titlejy" :class="{ 'green': rhindex == 2 }" @click="rechangeindex(2)">{{ $t('heads.title38') }}
            </div>
            <div class="titlejy" :class="{ 'green': rhindex == 3 }" @click="rechangeindex(3)">{{ $t('heads.title39') }}
            </div>
          </div>
          <div class="infobox" v-if="rhindex == 1">
            <div v-if="rechindex == 1">
              <div class="title">{{ $t('heads.title40') }}</div>
              <div class="infoitem displays" @click="torechange(i, i.min)" v-for="(i, v) in chlist">

                <img :src="i.ico" alt="">

                <div>{{ i.name }}</div>
              </div>
            </div>
            <div v-if="rechindex == 2">
              <div class="titlename">{{ $t('heads.title41') }}</div>
              <input type="text" v-model="valuecode">
              <div style="color: #fff;" v-html="cztype.pay_tips"></div>
              <div class="btn" style="margin: 3rem 0rem;color: #000;" @click="tonewrechange()">{{ $t('heads.title42') }}
              </div>
            </div>
            <rechange v-if="rechindex == 3" :id="czid"></rechange>
          </div>
          <div class="infobox" v-if="rhindex == 2">

            <div v-if="witindex == 1">
              <div class="title">{{ $t('heads.title40') }}</div>
              <div class="infoitem displays" @click="towithdraw(v)" v-for="(i, v) in bilist">

                <img :src="i.ico" alt="">

                <div>{{ i.coin }}</div>
              </div>
            </div>
            <withdraw v-if="witindex == 2" :id="txid"></withdraw>

          </div>
          <div class="infobox" v-if="rhindex == 3">
            <div class="titlename">{{ $t('heads.title43') }}</div>
            <input type="text" v-model="coupon">
            <div class="btn" style="margin: 3rem 0rem;color: #000;" @click="getcoupon()">{{ $t('heads.title42') }}</div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import { successNotification } from "../utils/el-ementui"
import rechange from '@/components/rechange.vue'
import withdraw from '@/components/withdraw.vue'
import { login, getregister, getsend, getchangepwd, getuserinfo, getcoin, getpayment, getcoupon, getwitrech,getlanguage } from '../api/index'
export default {
  name: 'HelloWorld',
  props: ['index'],
  components: {
    rechange,
    withdraw
  },
  data() {
    return {
      radio: 0,
      langradio:0,
      rhindex: 1,
      witindex: 1,
      show: false,
      regshow: false,
      fpshow: false,
      fundsshow: false,
      login: {
        email: '',
        password: '',
      },
      reg: {
        name: '',
        email: '',
        password: '',
        jypassword: '',
        code: '',
      },
      wj: {
        email: '',
        code: '',
        oldpassword: '',
        newpassword: '',
      },
      valuecode: '',
      rechindex: 1,
      visible: false,
      isloding: 1,
      visibles: false,
      visibless: false,
      bilist: [],
      chlist: [],
      coupon: '',
      czmin: '',
      cztype: '',
      czid: '',
      isclick: 1,
      txid: '',
      biimg: '',
      bipic: '',
      newuser: '',
      languagelist:[]
    }
  },
  created() {
     this.getlanguage()
    // console.log(this.$route.query.code)
    if (this.$route.query.code) {

      this.fpshow = false
      this.show = false
      this.fundsshow = false
      this.regshow = true

      this.reg.code = this.$route.query.code
    }
    this.getuserinfo()
    if (localStorage.getItem('App-Token')) {
      this.value = 1
    } else {
      this.value = 2
    }

    this.getcoin()
    this.getpayment()

  },
  methods: {
    changeindex(index) {
      if (index == 1) {
        this.$router.push('/')
      }
      if (index == 2) {
        this.$router.push('/Playground')
      }
      if (index == 6) {
        this.$router.push('/Promotion')
      }
      if (index == 5) {
        this.$router.push('/support')
      }
    },
    showlogin() {
      this.fpshow = false
      this.regshow = false
      this.fundsshow = false
      this.show = true
    },
    showreg() {
      this.fpshow = false
      this.show = false
      this.fundsshow = false
      this.regshow = true
    },
    tofpshow() {
      this.show = false
      this.regshow = false
      this.fundsshow = false
      this.fpshow = true
    },
    funds() {
      this.show = false
      this.fpshow = false
      this.regshow = false
      this.fundsshow = true

      this.rechindex = 1
      this.witindex = 1
      this.valuecode = ''
    },
    rechangeindex(index) {
      this.rhindex = index
      this.rechindex = 1
      this.witindex = 1
      this.valuecode = ''
    },
    torechange(index, min) {

      this.rechindex = 2
      this.czmin = min
      this.cztype = index
    },
    tonewrechange() {

      Adjust.trackEvent({
        eventToken: 'cruof8',
        callbackParams: [
          { key: "revenue_amount", value: "rechange" },
        ],
      })

      // this.rechindex = 3
      // console.log(this.cztype)
      if (this.valuecode < this.czmin) {

        successNotification(this.$t('heads.title44') + this.czmin)
        return
      }

      if (this.cztype.type == 1) {
        this.czid = this.cztype.type
        this.rechindex = 3
      } else {

        this.getwitrech(this.cztype.url)
      }
    },
    async getwitrech(url) {
      if (this.isclick == 1) {
        this.isclick = 0
        let data = {
          type: 1,
          price: this.valuecode
        }
        const res = await getwitrech(url, data)
        // console.log(res);

        if (res.code == 1) {
          window.location.href = res.data.url
        } else {

          successNotification(res.msg)

          setTimeout(() => {
            this.isclick = 1
          }, 1000)
        }
      }


    },
    towithdraw(index) {

      this.txid = index
      this.witindex = 2
    },
    toactive(index) {
      this.visibless=false
      this.visibles=false
      this.$emit('sonindex', index)
      this.$router.push({
        path: '/Activity',
        query: {
          id: index
        }
      })
    },
    // 登录的逻辑
    tologin() {

      if (!this.login.email) {

        successNotification(this.$t('heads.title45'))
        return
      }

      if (!this.login.password) {

        successNotification(this.$t('heads.title46'))
        return
      }

      login({
        account: this.login.email,
        password: this.login.password
      }).then(res => {
        successNotification(res.msg)

        if (res.code == 200) {

          Adjust.trackEvent({
            eventToken: 'icy32d',
            callbackParams: [
              { key: "revenue_amount", value: "login success" },
            ],
          })

          localStorage.setItem('storage_data', JSON.stringify(res.data.userinfo))
          localStorage.setItem('App-Token', res.data.userinfo.token)
          setTimeout(() => {
            this.$router.go(0)
          }, 1200)
        }
      })
    },
    // 注册
    async getregister() {

      if (!this.reg.name) {
        successNotification(this.$t('heads.title53'))
        return
      }

      if (!this.reg.email) {
        successNotification(this.$t('heads.title45'))
        return
      }

      if (!this.reg.password) {
        successNotification(this.$t('heads.title46'))
        return
      }

      if (!this.reg.jypassword) {
        successNotification(this.$t('heads.title47'))
        return
      }

      let data = {
        account: this.reg.email,
        invitation_code: this.reg.code,
        password: this.reg.password,
        userrname: this.reg.name,
        password2: this.reg.jypassword,

      }
      const res = await getregister(data)
      // console.log(res);
      successNotification(res.msg)
      if (res.code == 1) {
        Adjust.trackEvent({
          eventToken: 'bel4b5',
          callbackParams: [
            { key: "revenue_amount", value: "register success" },
          ],
        })
        login({
          account: this.reg.email,
          password: this.reg.password
        }).then(res => {
          // successNotification(res.msg)
          if (res.code == 200) {

            Adjust.trackEvent({
              eventToken: 'icy32d',
              callbackParams: [
                { key: "revenue_amount", value: "login success" },
              ],
            })

            localStorage.setItem('storage_data', JSON.stringify(res.data.userinfo))
            localStorage.setItem('App-Token', res.data.userinfo.token)
            setTimeout(() => {
              this.$router.go(0)
            }, 1200)
          }
        })
      }
    },
    //忘记密码
    async getuserinfo() {
      const res = await getuserinfo()
      this.newuser = res.data
      this.wj.email = res.data.username

    },
    getcode() {
      if (!this.wj.email) {
        successNotification(this.$t('heads.title45'))
        return
      }
      this.fscode()
    },
    fscode() {
      if (this.isloding == 1) {
        this.isloding = 0

        let data = {
          username: this.wj.email,
          event: 'changepwd'
        }

        getsend(data).then((res) => {
          // console.log(res);
          successNotification(res.msg)

          if (res.code == 1) {
            let codes = document.querySelector('#codes')

            let times = 60
            this.settime = setInterval(() => {
              if (times <= 1) {
                clearInterval(this.settime)
                codes.innerHTML = 'code'
                this.isloding = 1
              } else {
                times = times - 1
                codes.innerHTML = times + 's'
              }
            }, 1000)
          } else {
            setTimeout(() => {
              this.isloding = 1
            }, 1000)
          }
        })

      }
    },
    async changepass() {
      if (!this.wj.email) {
        successNotification(this.$t('heads.title45'))
        return
      }
      if (!this.wj.code) {
        successNotification(this.$t('heads.title48'))
        return
      }
      if (!this.wj.oldpassword) {
        successNotification(this.$t('heads.title49'))
        return
      }
      if (!this.wj.newpassword) {
        successNotification(this.$t('heads.title50'))
        return
      }
      if (this.wj.oldpassword != this.wj.newpassword) {
        successNotification(this.$t('heads.title51'))
        return
      }

      let data = {
        type: 1,
        username: this.wj.email,
        codes: this.wj.code,
        password: this.wj.oldpassword
      }


      const res = await getchangepwd(data)
      // console.log(res);
      successNotification(res.msg)

      if (res.code == 1) {

        setTimeout(() => {
          this.showlogin()
        }, 1200)

      }
    },
    // 充值和提现
    async getcoin() {
      const res = await getcoin()
      this.bilist = res.data
    },
    async getpayment() {
      const res = await getpayment()
      this.chlist = res.data

    },
    // 兑换
    async getcoupon() {

      if (!this.coupon) {
        successNotification(this.$t('heads.title52'))
        return
      }

      let data = {
        Code: this.coupon
      }
      const res = await getcoupon(data)

      successNotification(res.msg)
    },
    // bi切换
    async xzradio(name) {
      console.log(name)
      this.biimg = name.ico
      this.bipic = name.money
      this.visibles = false
    },
    tokefu() {
      window.location.href = this.newuser.customer
    },
    async getlanguage() {
				const res =await getlanguage()
				// console.log(res);
				this.languagelist=res.data
				res.data.forEach((i,v)=>{
					if(localStorage.getItem('lang')==i.lang) {
						this.langradio=v
					}
				})
			},
      changelang(i) {
        // console.log(i)
        
        if(i.lang == 'zh-cn') {
          this.$i18n.locale = 'zh'
          this.$router.go(0)
        }else {
          this.$i18n.locale = i.lang
          this.$router.go(0)
        }

        localStorage.setItem('lang',i.lang)
				localStorage.setItem('lg',i.name)
        this.visibless = false
      }
  }
}
</script>


<style lang="scss">

.myredio {
  width: 13rem !important;
  border: none !important;
  background-color: #131a22 !important;
  color: #fff !important;
  .van-radio {
  color: #fff !important;
  margin-bottom: .5rem;
}
.van-radio__label {

color: #fff !important;
}
}

.mypopover {
  background-color: #131a22 !important;
  border: none !important;
  width: 13rem !important;
  min-height: 6rem !important;

  .itemboxs {
    // padding: 1rem;

    .baoxi {
      border-radius: .1rem;
      padding: .6rem 1rem;
      color: #fff;
      display: flex;
      align-items: center;

      .baoxiang {
        width: 1.3rem;
        height: 1.1rem;
        margin-right: .5rem;
      }

      // margin-bottom: 1rem;
    }

    .baoxi:hover {
      background-color: #00FF86;
      color: #000;
    }

  }


}

.headbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  width: 87rem;
  margin: 0 auto;
  cursor: pointer;

  .centerbox {
    display: flex;

    .left {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      color: #fff;

      img {
        width: 1.75rem;
        margin-right: .3rem;
      }

    }

    .center {
      margin-left: 3.5rem;
      color: #7B8997;

      .top {
        display: flex;
        font-size: .75rem;
        color: #5E6A76;

        div {
          margin-right: 1.4375rem;
        }
      }

      .bot {
        font-size: .875rem;
        margin-top: .5rem;

        div {
          margin-right: 1.4375rem;
        }

        .xzdiv {
          margin-right: 1.4375rem;
          color: #fff;
        }
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    padding-right: .5rem;
    color: #fff;

    div {
      padding: .5rem 1.5rem;
      background-color: red;
      border-radius: .3125rem;
      background-color: #1D2329;

    }

    div:nth-child(2) {
      margin: 0rem 1rem;
      background-color: #00FF86;
      color: #000;
    }

    img {
      width: 2.4375rem;
    }
  }

  .rights {
    .well {
      width: 8.5rem;
      height: 39px;
      background-color: #131A22;
      padding: 0rem .8rem;

      .left {
        color: #FFFFFF;
        font-size: .8rem;

        .bi {
          width: 1.2rem;
          height: 1.2rem;
          margin-right: .5rem;
        }
      }
    }

    .qianbao {
      background-color: #00FF86;
      color: #000;
      margin: 0rem .5rem;
      width: 5rem;
      height: 1.8rem;
      text-align: center;
      line-height: 1.8rem;
      border-radius: .3rem;

    }

    .active {
      margin-right: .5rem;
      width: 1.8rem;
      height: 1.8rem;
      // background-color: #00FF86;
      border-radius: 2px;
    }
  }

}

// ::v-deep .van-popup .van-popup--center {
//   background-color: #131A22;
//   // border-radius: 1.3rem !important;
// }
.custom-popup .van-popup__content {
  background-color: #131A22;

}

.loginbox {
  width: 42.75rem;
  height: 40.75rem;
  background-color: #131A22;

  // border-radius: 1.3rem !important;
  .leftimg {
    height: 40.75rem;
  }

  .login,
  .reg {
    flex: 1;
    height: 100%;
    padding: 1rem;
    font-size: .9rem;


    .lotitle {
      margin-bottom: 3rem;
      display: flex;
      align-items: center;

      img {
        width: 1.75rem;
        height: 1.75rem;
      }

      div {
        color: #fff;
        margin-left: .3rem;
      }
    }

    .name {
      color: #818E9D;
      text-align: left;
    }

    input {
      width: 100%;
      background-color: #1D2329;
      padding: 1rem;
      box-sizing: border-box;
      color: #fff;
      margin: 1.3rem 0rem;
      border-radius: .5rem;
    }

    input::-webkit-input-placeholder {
      color: #818E9D;
      font-size: .9rem;
    }

    .btn {
      width: 100%;
      height: 2.8rem;
      background-color: #00FF86;
      text-align: center;
      line-height: 2.8rem;
      font-weight: 600;
      border-radius: .5rem;
      margin-top: 5rem;
    }

    .toreg {
      margin-top: 9rem;
      color: #818E9D;
      font-size: .9rem;

      span {
        color: #fff;
      }
    }
  }

  .reg {
    min-height: 100%;
    overflow-y: scroll;
    border-radius: 1rem;

    .sendcode {
      position: relative;

      .codes {
        padding: .5rem 1rem;
        background-color: #00FF86;
        border-radius: .3rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
      }
    }

    .topjy {
      // background-color: #252C31;
      // padding: .3rem .8rem;
      color: #707D8A;
      display: flex;
      border-radius: .5rem;
      margin-top: 1.5rem;

      .titlejy {
        padding: .5rem 1.5rem;
        border-radius: .5rem;
        // background-color: #fff;

      }

      .green {
        background-color: #1D2329;
        color: #00FF86;
      }
    }

    .infobox {
      color: #fff;

      .infoitem {
        background-color: #252c31;
        padding: .5rem 1rem;
        border-radius: .5rem;
        margin-bottom: 1rem;

        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          // background-color: #fff;
        }

      }

      .title {

        margin: 1.5rem 0rem;
      }

      .titlename {
        color: #fff;
        margin-top: 1.5rem;
      }

    }

  }

}
</style>
