// 1、创建中文语言包对象
export default {
    heads: {
        title: 'হোমপেজ',
        title1: 'ন্যায়সংগত প্রমাণ',
        title2: 'গেমিং এলাকা',
        title3: 'প্রচার',
        title4: 'ট্রেজার',
        title5: 'পুরস্কার',
        title6: 'নিয়ম',
        title7: 'বেটিং রিবেট',
        title8: 'ডিপোজিট রিবেট',
        title9: 'ইভেন্ট',
        title10: 'সাপোর্ট',
        title11: 'লগইন',
        title12: 'রেজিস্টার',
        title13: 'ওয়ালেট',
        title14: 'ইমেইল',
        title15: 'আপনার ইমেইল প্রবেশ করুন',
        title16: 'পাসওয়ার্ড',
        title17: 'আপনার পাসওয়ার্ড প্রবেশ করুন',
        title18: 'পাসওয়ার্ড ভুলে গেছেন?',
        title19: 'একাউন্ট নেই?',
        title20: 'রেজিস্টার করুন',
        title21: 'ইউজারনেম',
        title22: 'আপনার ইউজারনেম প্রবেশ করুন',
        title23: 'লেনদেন পাসওয়ার্ড',
        title24: 'আপনার লেনদেন পাসওয়ার্ড প্রবেশ করুন',
        title25: 'আমন্ত্রণ কোড',
        title26: 'আপনার আমন্ত্রণ কোড প্রবেশ করুন',
        title27: 'একাউন্ট আছে?',
        title28: 'লগইন করুন',
        title29: 'পাসওয়ার্ড ভুলে গেছেন',
        title30: 'ভেরিফিকেশন কোড',
        title31: 'আপনার ভেরিফিকেশন কোড প্রবেশ করুন',
        title32: 'নতুন পাসওয়ার্ড',
        title33: 'আপনার নতুন পাসওয়ার্ড প্রবেশ করুন',
        title34: 'পাসওয়ার্ড নিশ্চিত করুন',
        title35: 'আপনার পাসওয়ার্ড নিশ্চিত করুন',
        title36: 'পরিবর্তন',
        title37: 'ডিপোজিট',
        title38: 'উত্তোলন',
        title39: 'এক্সচেঞ্জ',
        title40: 'ক্রিপ্টোকারেন্সি',
        title41: 'ডিপোজিট পরিমাণ প্রবেশ করুন',
        title42: 'নিশ্চিত করুন',
        title43: 'প্রচার কোড বা উপহার কার্ড কোড প্রবেশ করুন',
        title44: 'ডিপোজিট কমপক্ষে হতে হবে',
        title45: 'ইমেইল খালি থাকতে পারে না',
        title46: 'পাসওয়ার্ড খালি থাকতে পারে না',
        title47: 'লেনদেন পাসওয়ার্ড খালি থাকতে পারে না',
        title48: 'ভেরিফিকেশন কোড খালি থাকতে পারে না',
        title49: 'নতুন পাসওয়ার্ড খালি থাকতে পারে না',
        title50: 'পাসওয়ার্ড নিশ্চিত করা খালি থাকতে পারে না',
        title51: 'পাসওয়ার্ড দুটি আলাদা',
        title52: 'এক্সচেঞ্জ কোড খালি থাকতে পারে না',
        title53: 'ইউজারনেম খালি থাকতে পারে না'
    },
    ggtext: {
        title: 'কপি সফল',
        title1: 'কপি ব্যর্থ',
        title2: 'লোড হচ্ছে...',
        title3: 'আরও নেই'
    },
    rechange: {
        title: 'ডিপোজিট',
        title1: 'আপনার',
        title2: 'ডিপোজিট ঠিকানা'
    },
    withdraw: {
        title: 'উত্তোলন',
        title1: 'উত্তোলন ঠিকানা',
        title2: 'ব্যাংক অ্যাকাউন্ট নম্বর',
        title3: 'পরিমাণ',
        title4: 'লেনদেন পাসওয়ার্ড প্রবেশ করুন',
        title5: 'নিশ্চিত করুন',
        title6: 'প্রথমে USDT ঠিকানা বাঁধুন',
        title7: 'প্রথমে ব্যাংক অ্যাকাউন্ট নম্বর বাঁধুন',
        title8: 'পরিমাণ খালি থাকতে পারে না',
        title9: 'লেনদেন পাসওয়ার্ড খালি থাকতে পারে না'
    },
    user: {
        title: 'ব্যাংক তথ্য বাঁধাই',
        title1: 'দেশ',
        title2: 'ঠিকানা',
        title3: 'ব্যাংকের নাম',
        title4: 'ব্যাংক অ্যাকাউন্ট নম্বর',
        title5: 'নাম',
        title6: 'ইমেইল',
        title7: 'নিশ্চিত করুন',
        title8: 'পরিবর্তন',
        title9: 'ঠিকানা খালি থাকতে পারে না',
        title10: 'ব্যাংকের নাম নির্বাচন করুন',
        title11: 'কার্ড নম্বর প্রবেশ করুন',
        title12: 'নাম প্রবেশ করুন',
        title13: 'ইমেইল প্রবেশ করুন',
        title14: 'লেনদেন',
        title15: 'সব',
        title16: 'ডিপোজিট',
        title17: 'উত্তোলন',
        title18: 'পরিমাণ',
        title19: 'সময়',
        title20: 'অবশিষ্ট ডিপোজিট',
        title21: 'সফল',
        title22: 'ব্যর্থ',
        title23: 'পর্যালোচনাধীন',
        title24: 'পেমেন্ট চলছে',
        title25: 'গেমিং রেকর্ড',
        title26: 'বেট',
        title27: 'লাভ',
        title28: 'তথ্য',
        title29: 'ব্যাংক তথ্য বাঁধাই',
        title30: 'নোটিফিকেশন',
        title31: 'পরিসংখ্যান',
        title32: 'লেনদেন পাসওয়ার্ড পরিবর্তন করুন',
        title33: 'লেনদেন রেকর্ড',
        title34: 'গেমিং রেকর্ড',
        title35: 'লগ আউট',
        title36: 'আপলোড ব্যর্থ, দয়া করে আবার চেষ্টা করুন',
        title37: 'ব্যবহারকারীর তথ্য',
        title38: 'ইউজারনেম',
        title39: 'বার্তা নোটিফিকেশন',
        title40: 'পরিচিতি',
        title41: 'বিস্তারিত',
        title42: 'পরিসংখ্যান',
        title43: 'খেলা হয়েছে',
        title44: 'বেটেড',
        title45: 'নিট লাভ',
        title46: 'গত ২৪ ঘণ্টার পরিসংখ্যান',
        title47: 'গেম',
        title48: 'সর্বাধিক বেট',
        title49: 'সর্বাধিক লাভ',
        title50: 'ভেরিফিকেশন কোড',
        title51: 'নতুন পাসওয়ার্ড',
        title52: 'পাসওয়ার্ড নিশ্চিত করুন',
        title53: 'ইমেইল খালি থাকতে পারে না',
        title54: 'ভেরিফিকেশন কোড খালি থাকতে পারে না',
        title55: 'নতুন পাসওয়ার্ড খালি থাকতে পারে না',
        title56: 'পাসওয়ার্ড নিশ্চিত করুন',
        title57: 'পাসওয়ার্ড দুটি আলাদা'
    },
    Activity: {
        title: 'আমন্ত্রণ লিঙ্ক',
        title1: 'কার্যকরী অভ্যন্তরীণ',
        title2: 'ব্যক্তি',
        title3: 'কার্যকরভাবে বৃদ্ধি করা খেলোয়াড় সংখ্যা কত?',
        title4: 'অভ্যন্তরীণ মোট চার্জ',
        title5: '২ বা তার বেশি',
        title6: 'সুপরিশীল আক্রমণকারী মুষ্টিসংগ্রহ',
        title7: '৭২ বা তার বেশি'
    },
    award: {
        title: 'ব্যর্থতার পরিমাণ',
        title1: 'উপহার পরিমাণ'
    },
    Betting: {
        title: 'বেটিং লেনদেন রিবেট',
        title1: 'প্রথম স্তরের সরাসরি প্ররোচনা',
        title2: 'দ্বিতীয় স্তরের সরাসরি প্ররোচনা',
        title3: 'তৃতীয় স্তরের সরাসরি প্ররোচনা'
    },
    home: {
        title: 'স্বাগতম ফিরে',
        title1: 'অনুসন্ধান বিষয়বস্তু প্রবেশ করুন',
        title2: 'গেমিং এলাকা',
        title3: 'গেমে প্রবেশ করুন',
        title4: 'স্পোর্টস বেটিং',
        title5: 'সাম্প্রতিক বিজয়',
        title6: 'মোট বেট',
        title7: 'লাইভ বেট',
        title8: 'উচ্চ মূল্যবান খেলোয়াড়',
        title9: 'সৌভাগ্যবান জয়',
        title10: 'গেম',
        title11: 'ব্যবহারকারী',
        title12: 'সময়',
        title13: 'পরিমাণ'
    },
    games: {
        title: 'নিয়মিত',
        title1: 'ডেমো',
        title2: 'গেম শুরু করুন',
        title3: 'এখনো কোনো ডেমো উপলব্ধ নেই'
    },
    Playground: {
        title: 'গেমিং এলাকা',
        title1: 'অনুসন্ধান বিষয়বস্তু প্রবেশ করুন',
        title2: 'যাদৃচ্ছভাবে নির্বাচন',
        title3: 'সরবরাহকারী',
        title4: 'শর্ত অনুসারে শ্রেণীবদ্ধ করুন',
        title5: 'শীর্ষে ফিরে যান'
    },
    Promotion: {
        title: 'প্রচার সহযোগী',
        title1: 'নিচে দেওয়া কোডটি তৈরি করুন এবং ফ্রি পুরস্কার জিতুন',
        title2: 'আমন্ত্রণ লিঙ্ক',
        title3: 'সরাসরি প্রস্তাবিত সংখ্যা',
        title4: 'প্রথম ডিপোজিটকারী সংখ্যা',
        title5: 'ডিপোজিটকারী সংখ্যা',
        title6: 'দলের মোট ডিপোজিট',
        title7: 'দলের মোট পরিমাণ',
        title8: 'দলের সদস্যরা',
        title9: 'তথ্য পর্যালোচনা',
        title10: 'দল',
        title11: 'অন্যান্য সদস্যরা',
        title12: 'লেনদেন',
        title13: 'মোট রাজস্ব',
        title14: 'সরাসরি প্রস্তাবিত লেনদেন',
        title15: 'অন্যান্য লেনদেন',
        title16: 'কমিটি',
        title17: 'মোট কমিশন',
        title18: 'সরাসরি সুপারিশ কমিশন',
        title19: 'অন্যান্য কমিশন'
    },
    RechargeRebate: {
        title: 'ডিপোজিট লেনদেন রিবেট',
        title1: 'প্রথম ডিপোজিট',
        title2: 'পুরস্কার'
    },
    rule: {
        title: 'রাজস্ব পৌঁছাতে হবে',
        title1: 'ডিপোজিট পরিমাণ প্রয়োজন',
        title2: 'সাপ্তাহিক পুরস্কার',
        title3: 'মাসিক পুরস্কার',
        title4: 'সদস্য উন্নয়ন পুরস্কার',
        title5: '*এই প্ল্যাটফর্মে দৈনিক উত্তোলনের সংখ্যা, দৈনিক উত্তোলনের সীমা, এককালীন উত্তোলনের সীমা কোনো সীমা নেই।'
    },
    support: {
        title: 'ন্যায্যতা',
        title1: 'গেম ন্যায্য কি?',
        title2: 'আমরা একটি ন্যায্য এবং সুষ্ঠু পূর্বাভাস প্ল্যাটফর্ম, আমাদের লক্ষ্য সমস্ত অন্যায় কেটে ফেলা এবং খেলোয়াড়দেরকে নিরাপদ ও আনন্দময় অভিজ্ঞতা প্রদান করা।',
        title3: 'আমরা মোট ১০ মিলিয়ন হ্যাশ তৈরি করেছি (জেনারেটেড চেইন যাচাইযোগ্য), প্রতিটি হ্যাশ একটি Crash মাল্টিপ্লায়ারকে প্রতিনিধিত্ব করে।',
        title4: 'আমরা এই ১০ মিলিয়ন সংখ্যাকে বিপরীত ক্রমে প্রকাশ করি, প্রতিটি সংখ্যা একটি গেম রাউন্ডকে প্রতিনিধিত্ব করে (অর্থাৎ আমাদের মোট ১০ মিলিয়ন রাউন্ড রয়েছে)।',
        title5: 'অর্থাৎ, প্রতি রাউন্ডের ক্র্যাশ সংখ্যা আগে থেকেই বিদ্যমান, খেলা শুরু হওয়ার পর গণনা করা হয় না, তাই খেলোয়াড়রা নির্ভয়ে বাজি ধরতে পারেন।',
        title6: 'গেম কি প্ল্যাটফর্ম দ্বারা প্রভাবিত হতে পারে?',
        title7: 'ইন্টিগ্রিটি চেক ভ্যালু হল প্রধান কী যা অফিসিয়াল ম্যানিপুলেশন সনাক্ত করে; টেস্ট অ্যালগরিদম নিচে উল্লেখিত।',
        title8: 'উদাহরণ: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: 'একটি 2^52 পরিসরের র্যান্ডম মান নিন, অর্থাৎ 16^13, অর্থাৎ 13 ডিজিটের হেক্সাডেসিমেল সংখ্যা (কারণ হ্যাশ মান হেক্সাডেসিমেল, তাই 2^52 === 16^13) 6b5124897c3c4 (0x6b5124897c3c4 দশমিক সংখ্যায় 1887939992208324)।',
        title10: 'র্যান্ডম মানকে 0~1 এর মধ্যে ছড়িয়ে দিন, অর্থাৎ 13 টি fs এর সর্বাধিক মান 0x6b5124897c3c4/0x100000000000000 দ্বারা ভাগ করুন। হ্যাশ মানের বিচ্ছিন্ন র্যান্ডমের কারণে, আমরা ধরে নিতে পারি যে যেকোনো হ্যাশ মান 0~1 এর মধ্যে একটি র্যান্ডম সংখ্যা হিসাবে রূপান্তরিত হতে পারে, 1887939992208324/4503599627370496 = 0.419206889692064।',
        title11: 'বুকমেকার মুনাফা 1%। আরও গণনা করুন 99/(1-X), যেখানে X হল ধাপ 2 এ গণনা করা র্যান্ডম মান। যখন X = 0 তখন ফলাফল 99; যখন X = 1 তখন ফলাফল অমীমাংসিত; যখন X = 0.01 তখন ফলাফল 100; যখন X < 0.01 তখন ফলাফল 100 এর চেয়ে কম।',
        title12: 'উপসংহার: সাধারণ র্যান্ডম সংখ্যা বিতরণ 99 থেকে অমীমাংসিত; এবং যখন র্যান্ডম সংখ্যা বিতরণ 0~0.01 তখন ফলাফল 100 এর চেয়ে কম।',
        title13: '99/(1-0.419206889692064) = 170.45656748150867 সমস্ত 100 এর কম মান 100 হিসাবে সেট করা হবে। অন্য কথায়, 100 এর সম্ভাবনা 1%। সংখ্যা রাউন্ড এবং 100 দ্বারা ভাগ করুন এবং চূড়ান্ত ফলাফল পান। 170/100 = 1.70',
        title14: 'উপসংহার: গেমে ব্যবহৃত হ্যাশ মানগুলি বিপরীত ক্রমে রয়েছে, তাই SHA256 অ্যালগরিদম দ্বারা, গেমে ব্যবহৃত পূর্ববর্তী হ্যাশ মানের ভিত্তিতে এই হ্যাশ মানের পরবর্তী হ্যাশ মান গণনা করা যায়। যেহেতু হ্যাশ মানের কীগুলি একে অপরকে সম্পর্কিত, তাই আমরা এটি বলতে পারি: যদি একটি হ্যাশ মান তার পূর্ববর্তী হ্যাশ মানের ভিত্তিতে গণনা করা যায়, তাহলে এই হ্যাশ মানটি পূর্ববর্তী পুরস্কার প্রকাশের সময় তৈরি হওয়া উচিত। একইভাবে, পুরো হ্যাশ চেইন শুরুতেই তৈরি করা হয়েছিল, একবার তৈরি হলে এটি পরিবর্তন করা যাবে না, অন্যথায় এটি SHA256 যাচাইকরণ দ্বারা যাচাই করা যাবে না। পুরস্কারটি কেবল একটি সম্ভাবনা গেম, ক্র্যাশ অনিবার্য, অফিসিয়াল হোস্টিং কোনও গেম সেটিং নিয়ন্ত্রণ করতে পারে না। তাই CRASH অন্যান্য জুয়ার পদ্ধতির তুলনায় আরও স্বচ্ছ, এটি আমাদের গেম তৈরির ভিত্তি।',
        title15: 'সরল গণনা:',
        title16: 'যখন 13 ডিজিটের হ্যাশ মান 8000000000000 = 1.98, 0xb000000000 = 3.168, 0xc000000000 = 3.96 হয়, অর্থাৎ প্রথম সংখ্যা 8 এর বেশি ((16-8)/16≈0.5), ফলাফল প্রায় 2x; যখন প্রথম সংখ্যা b এর বেশি ((16-11)/16≈0.3), ফলাফল প্রায় 3x; যখন প্রথম সংখ্যা c এর বেশি ((16-12)/16≈0.25), ফলাফল প্রায় 4x, বাকি ক্ষেত্রেও একইভাবে।',
        title17: 'যখন 13 ডিজিটের হ্যাশ মান f000000000000 = 15.84, ff00000000000 = 253.44, fff000000',
        title18: 'ন্যায়তা প্রমাণ',
        title19: 'আমাদের সাথে যোগাযোগ করুন',
        title20: 'সাধারণ প্রশ্ন',
        title21: 'অনলাইন সহায়তা'
    },
    Treasure: {
        title: 'বন্ধুদের আমন্ত্রণ জানিয়ে বক্স পান',
        title1: 'ইতিহাস',
        title2: 'গ্রহণ করা হয়েছে',
        title3: 'গ্রহণ করুন',
        title4: 'গ্রহণ করা হয়নি',
        title5: 'কর্মসূচীর সময় (UTC-3):',
        title6: '২০২৪/০৪/২৬ ০০:০০:০০-২০২৮/০৪/৩০ ২৩:৫৯:৫৯ দুই, কর্মসূচীর শর্ত:',
        title7: 'শুধুমাত্র নতুন রিপোর্ট করা অধস্তন এবং অধস্তনরা এই কর্মসূচীতে অংশগ্রহণের যোগ্য',
        title8: '১. বন্ধুদের আমন্ত্রণ জানানোর মাধ্যমে বক্স খুলুন, বিভিন্ন সংখ্যক আমন্ত্রণের মাধ্যমে যথাযথ বক্স পেতে পারেন, সর্বোচ্চ পরিমাণ ২০০০০০০ টাকা, যত বেশি বন্ধু আমন্ত্রণ জানাবেন, পুরস্কার তত বেশি;',
        title9: '২. এই কর্মসূচী প্ল্যাটফর্মের অতিরিক্ত উপহার, অন্যান্য পুরস্কার এবং এজেন্ট কমিশনের পাশাপাশি উপভোগ করতে পারবেন, সরাসরি বিভিন্ন আনন্দ উপভোগ করুন;',
        title10: '৩. পুরস্কার শুধুমাত্র অ্যাপ/iOS, APP/Android দ্বারা ম্যানুয়ালি নেওয়া যাবে, পেরিয়ে যাওয়া পুরস্কার স্বয়ংক্রিয়ভাবে বিতরণ করা হবে;',
        title11: '৪. এই কর্মসূচীর বিতরণকৃত পুরস্কার (মাস্টার সুপারিশ নয়) বৈধ বাজি পূরণ করতে হবে, বাজির ক্ষেত্র শুধুমাত্র স্লট মেশিন: সমস্ত গেম;',
        title12: '৫. এই কর্মসূচী শুধুমাত্র অ্যাকাউন্ট মালিকদের নিয়মিত কার্যক্রমের জন্য, ভাড়া, এক্সটার্নাল হ্যাক, বট, বিভিন্ন অ্যাকাউন্ট বাজি, একে অপরকে বিপণন, মধ্যস্থতা, ইন্টারফেস, চুক্তি, আইনবিরোধী, গ্রুপ কন্ট্রোল ইত্যাদি প্রযুক্তি ব্যবহারের অনুমতি নেই, অন্যথায় পুরস্কার বাতিল বা কেটে দেওয়া হবে, অ্যাকাউন্ট বরফ হবে এবং এমনকি ব্ল্যাকলিস্টে রাখা হবে;',
        title13: '৬. পাঠ্যের ব্যাখ্যা নিয়ে বিভ্রান্তি এড়াতে, প্ল্যাটফর্ম এই কর্মসূচীর চূড়ান্ত ব্যাখ্যার অধিকার সংরক্ষণ করে।',
        title14: 'বক্স গ্রহণের রেকর্ড',
        title15: 'পরিমাণ',
        title16: 'সময়'
    }
    
}
