// 1、创建中文语言包对象
export default {
    heads: {
        title: 'Página de Inicio',
        title1: 'Prueba de Justicia',
        title2: 'Sala de Juegos',
        title3: 'Promoción',
        title4: 'Cofre del Tesoro',
        title5: 'Recompensas',
        title6: 'Reglas',
        title7: 'Reembolso de Apuestas',
        title8: 'Reembolso de Depósitos',
        title9: 'Eventos',
        title10: 'Soporte',
        title11: 'Iniciar Sesión',
        title12: 'Registrarse',
        title13: 'Billetera',
        title14: 'Correo Electrónico',
        title15: 'Ingrese su correo electrónico',
        title16: 'Contraseña',
        title17: 'Ingrese su contraseña',
        title18: '¿Olvidó su contraseña?',
        title19: '¿No tiene una cuenta?',
        title20: 'Regístrate',
        title21: 'Nombre de Usuario',
        title22: 'Ingrese su nombre de usuario',
        title23: 'Contraseña de Transacción',
        title24: 'Ingrese su contraseña de transacción',
        title25: 'Código de Invitación',
        title26: 'Ingrese su código de invitación',
        title27: '¿Ya tiene una cuenta?',
        title28: 'Iniciar sesión',
        title29: 'Olvidé mi contraseña',
        title30: 'Código de Verificación',
        title31: 'Ingrese su código de verificación',
        title32: 'Nueva Contraseña',
        title33: 'Ingrese su nueva contraseña',
        title34: 'Confirmar Contraseña',
        title35: 'Ingrese su contraseña de confirmación',
        title36: 'Modificar',
        title37: 'Depósito',
        title38: 'Retiro',
        title39: 'Intercambiar',
        title40: 'Criptomonedas',
        title41: 'Ingrese el monto del depósito',
        title42: 'Confirmar',
        title43: 'Ingrese el código promocional o el código de tarjeta de regalo',
        title44: 'El depósito no puede ser menor a',
        title45: 'El correo electrónico no puede estar vacío',
        title46: 'La contraseña no puede estar vacía',
        title47: 'La contraseña de transacción no puede estar vacía',
        title48: 'El código de verificación no puede estar vacío',
        title49: 'La nueva contraseña no puede estar vacía',
        title50: 'La contraseña de confirmación no puede estar vacía',
        title51: 'Las contraseñas no coinciden',
        title52: 'El código de intercambio no puede estar vacío',
        title53: 'El nombre de usuario no puede estar vacío'
    },
    ggtext: {
        title: 'Copia exitosa',
        title1: 'Error al copiar',
        title2: 'Cargando...',
        title3: 'No hay más',
    },
    rechange: {
        title: 'Depósito',
        title1: 'Su',
        title2: 'dirección de depósito',
    },
    withdraw: {
        title: 'Retiro',
        title1: 'Dirección de Retiro',
        title2: 'Número de Cuenta Bancaria',
        title3: 'Monto',
        title4: 'Ingrese la contraseña de transacción',
        title5: 'Confirmar',
        title6: 'Primero, vincule la dirección de USDT',
        title7: 'Primero, vincule el número de cuenta bancaria',
        title8: 'El monto no puede estar vacío',
        title9: 'La contraseña de transacción no puede estar vacía'
    },
    user: {
        title: 'Vincular Información Bancaria',
        title1: 'País',
        title2: 'Dirección',
        title3: 'Nombre del Banco',
        title4: 'Número de Tarjeta Bancaria',
        title5: 'Nombre',
        title6: 'Correo Electrónico',
        title7: 'Confirmar',
        title8: 'Modificar',
        title9: 'La dirección no puede estar vacía',
        title10: 'Seleccione el nombre del banco',
        title11: 'Ingrese el número de tarjeta',
        title12: 'Ingrese el nombre',
        title13: 'Ingrese el correo electrónico',
        title14: 'Transacción',
        title15: 'Todo',
        title16: 'Depósito',
        title17: 'Retiro',
        title18: 'Cantidad',
        title19: 'Tiempo',
        title20: 'No depositado',
        title21: 'Éxito',
        title22: 'Fallo',
        title23: 'Pendiente de Revisión',
        title24: 'En Pago',
        title25: 'Registro de Juegos',
        title26: 'Apuesta',
        title27: 'Ganancia',
        title28: 'Datos',
        title29: 'Vincular Información Bancaria',
        title30: 'Notificación',
        title31: 'Datos Estadísticos',
        title32: 'Modificar Contraseña de Transacción',
        title33: 'Registro de Transacciones',
        title34: 'Registro de Juegos',
        title35: 'Cerrar Sesión',
        title36: 'Error al subir, por favor vuelva a subir',
        title37: 'Información del Usuario',
        title38: 'Nombre de Usuario',
        title39: 'Notificaciones',
        title40: 'Introducción',
        title41: 'Detalles',
        title42: 'Datos Estadísticos',
        title43: 'Juegos Jugados',
        title44: 'Apuestas Realizadas',
        title45: 'Ganancia Neta',
        title46: 'Datos Estadísticos de las Últimas 24 Horas',
        title47: 'Juego',
        title48: 'Mayor Apuesta',
        title49: 'Mayor Ganancia',
        title50: 'Código de Verificación',
        title51: 'Nueva Contraseña',
        title52: 'Confirmar Contraseña',
        title53: 'El correo electrónico no puede estar vacío',
        title54: 'El código de verificación no puede estar vacío',
        title55: 'La nueva contraseña no puede estar vacía',
        title56: 'La confirmación de la contraseña no puede estar vacía',
        title57: 'Las contraseñas no coinciden'
    },
    Activity: {
        title: 'Enlace de Invitación',
        title1: 'Número de Personas Efectivas',
        title2: 'Personas',
        title3: '¿Cuántos jugadores se han mejorado efectivamente?',
        title4: 'Acumulación de Depósitos de Subordinados',
        title5: '2 o más',
        title6: 'SuoordinateAcumwou to the ostas',
        title7: '72 o más',
    },
    award: {
        title: 'Monto Mayor que el de Fallos',
        title1: 'Monto del Regalo',
    },
    Betting: {
        title: 'Recompensas por Flujo de Transacciones de Apuestas',
        title1: 'Primera Línea de Referencia',
        title2: 'Segunda Línea de Referencia',
        title3: 'Tercera Línea de Referencia',
    },
    home: {
        title: 'Bienvenido de Nuevo',
        title1: 'Ingrese el contenido de búsqueda',
        title2: 'Sala de Juegos',
        title3: 'Jugar',
        title4: 'Apuestas Deportivas',
        title5: 'Victorias Recientes',
        title6: 'Apuesta Total',
        title7: 'Apuestas en Vivo',
        title8: 'Grandes Apostadores',
        title9: 'Ganancia Afortunada',
        title10: 'Juego',
        title11: 'Usuario',
        title12: 'Tiempo',
        title13: 'Monto',
    },
    games: {
        title: 'Juego Completo',
        title1: 'Juego de Prueba',
        title2: 'Iniciar Juego',
        title3: 'Este juego no tiene versión de prueba',
    },
    Playground: {
        title: 'Sala de Juegos',
        title1: 'Ingrese el contenido de búsqueda',
        title2: 'Selección Aleatoria',
        title3: 'Proveedor',
        title4: 'Clasificación por Condiciones',
        title5: 'Volver Arriba',
    },
    Promotion: {
        title: 'Socios de Promoción',
        title1: 'Cree el código a continuación para ganar recompensas gratuitas',
        title2: 'Enlace de Invitación',
        title3: 'Número de Referencias Directas',
        title4: 'Número de Primeros Depósitos',
        title5: 'Número de Depósitos',
        title6: 'Total de Recargas del Equipo',
        title7: 'Monto Total del Equipo',
        title8: 'Miembros del Equipo',
        title9: 'Resumen de Datos',
        title10: 'Equipo',
        title11: 'Otros Miembros',
        title12: 'Flujo de Transacciones',
        title13: 'Total de Ventas',
        title14: 'Flujo Directo',
        title15: 'Otros Flujos',
        title16: 'Comisión',
        title17: 'Comisión Total',
        title18: 'Comisión por Referencias Directas',
        title19: 'Otras Comisiones',
    },
    RechargeRebate: {
        title: 'Recompensas por Flujo de Depósitos',
        title1: 'Primer Depósito',
        title2: 'Recompensa',
    },
    rule: {
        title: 'Requisitos de Volumen de Negocios',
        title1: 'Requisitos de Monto de Depósito',
        title2: 'Recompensa Semanal',
        title3: 'Recompensa Mensual',
        title4: 'Recompensa por Mejora de Membresía',
        title5: '*En esta plataforma no hay límite en el número de retiros diarios, el límite diario de retiros o el límite de retiro único.',
    },
    support: {
        title: 'Justicia',
        title1: '¿Es justo el juego?',
        title2: 'Somos una plataforma de predicciones justa y equitativa. Nuestro objetivo es eliminar todos los factores injustos para que los jugadores puedan jugar con confianza y disfrutar.',
        title3: 'Hemos generado un total de 10 millones de valores hash (la cadena generada es verificable), cada valor hash corresponde a un multiplicador de Crash.',
        title4: 'Publicamos estos 10 millones de números en orden inverso, cada número corresponde a una ronda del juego (es decir, tenemos un total de 10 millones de rondas).',
        title5: 'En otras palabras, la cantidad de colisiones en cada ronda ya está preexistente y no se calcula después de que comienza el juego, por lo que los jugadores pueden apostar con confianza.',
        title6: '¿El juego puede ser manipulado por la plataforma?',
        title7: 'El valor de verificación de integridad es clave para verificar la manipulación oficial; el algoritmo de prueba es el siguiente.',
        title8: 'Ejemplo: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: 'Tome un valor aleatorio dentro del rango de 2^52, es decir, 16^13, que es un número hexadecimal de 13 dígitos (como el valor hash es hexadecimal, 2^52 === 16^13) 6b5124897c3c4 (0x6b5124897c3c4 en decimal es igual a 1887939992208324).',
        title10: 'Distribuya el valor aleatorio entre 0 y 1, es decir, divídalo por el valor máximo en 13 fs 0x6b5124897c3c4/0x100000000000000. Considerando la aleatoriedad discreta del valor hash, podemos considerar que cualquier valor hash puede convertirse en un número aleatorio entre 0 y 1, 1887939992208324/4503599627370496 = 0.419206889692064.',
        title11: 'Suponga que la ventaja de la casa es del 1%. Calcule 99/(1-X), donde X es el valor aleatorio calculado en el paso 2. Cuando X es 0, el resultado es 99; cuando X es 1, el resultado es infinito positivo; cuando X es 0.01, el resultado es 100; cuando X es menor que 0.01, el resultado es menor que 100.',
        title12: 'Conclusión: La distribución general de números aleatorios es de 99 a infinito positivo; y cuando la distribución de números aleatorios es de 0 a 0.01, el resultado es menor que 100.',
        title13: '99/(1-0.419206889692064) = 170.45656748150867. Todos los valores menores a 100 se establecerán en 100. En otras palabras, la probabilidad de obtener 100 es del 1%. Redondee el número y divídalo por 100 para obtener el resultado final. 170/100 = 1.70',
        title14: 'Conclusión: Los valores hash utilizados en el juego están en orden inverso, por lo tanto, mediante el algoritmo SHA256, se puede calcular el siguiente valor hash a partir del valor hash anterior utilizado en el juego. Dado que las claves y los valores de los hash están emparejados uno a uno, podemos llegar a la conclusión de que si un valor hash puede calcular el valor hash anterior, entonces este valor hash ya debería haber sido generado en el momento de la publicación del premio anterior. Del mismo modo, toda la cadena de hash se genera al principio y una vez generada no se puede modificar, de lo contrario, no pasaría la verificación de SHA256. En cuanto al premio, es solo un juego de probabilidad; los crashes son inevitables y los organizadores oficiales no pueden manipular ninguna configuración del juego. Por lo tanto, CRASH es más transparente que otros métodos de apuestas, y esta transparencia es la base de nuestro juego.',
        title15: 'Cálculo Simple:',
        title16: 'Cuando el valor hash de 13 dígitos es 8000000000000 = 1.98, 0xb000000000 = 3.168, 0xc000000000 = 3.96, es decir, el primer dígito es mayor que 8 ((16-8)/16 ≈ 0.5), el resultado es aproximadamente 2x; cuando el primer dígito es mayor que b ((16-11)/16 ≈ 0.3), el resultado es aproximadamente 3x; cuando el primer dígito es mayor que c ((16-12)/16 ≈ 0.25), el resultado es aproximadamente 4x, y así sucesivamente.',
        title17: 'Cuando el valor hash de 13 dígitos es f000000000000 = 15.84, ff00000000000 = 253.44, fff000000',
        title18: 'Prueba de Justicia',
        title19: 'Contáctanos',
        title20: 'Preguntas Frecuentes',
        title21: 'Soporte en Línea',
    },
    Treasure: {
        title: 'Invita a tus Amigos para Obtener Cofres',
        title1: 'Historial',
        title2: 'Ya Recogido',
        title3: 'Recoger',
        title4: 'No Recogido',
        title5: 'Período del Evento (UTC-3):',
        title6: '2024/04/26 00:00:00 - 2028/04/30 23:59:59 II. Condiciones del Evento:',
        title7: 'Solo los nuevos subordinados y dependientes reportados son elegibles para participar en el evento III:',
        title8: '1. Invita a amigos para abrir cofres. Cuantos más amigos invites, más cofres obtendrás, con un monto máximo de 2 millones. Cuantos más amigos invites, más recompensas recibirás;',
        title9: '2. Este evento es un obsequio adicional de la plataforma y puede disfrutar simultáneamente de otras recompensas y comisiones de agentes, es decir, disfrutar directamente de varias diversiones;',
        title10: '3. Los premios solo pueden ser recogidos manualmente en la app/iOS o APP/Android. Los premios expirados se otorgarán automáticamente;',
        title11: '4. Los premios otorgados en este evento (excluyendo los principales) deben ser canjeados con apuestas válidas, y el rango de apuestas está limitado a tragamonedas: todos los juegos;',
        title12: '5. Este evento está limitado a la operación normal de los titulares de cuentas. Está prohibido participar mediante el alquiler, uso de trampas, bots, apuestas con diferentes cuentas, venta mutua, arbitraje, interfaces, protocolos, violaciones, control de grupos u otros métodos técnicos, de lo contrario, se cancelará o deducirá la recompensa, se congelará la cuenta o incluso se incluirá en la lista negra;',
        title13: '6. Para evitar discrepancias en la interpretación del texto, la plataforma se reserva el derecho de interpretación final de este evento.',
        title14: 'Registro de Recolección de Cofres',
        title15: 'Cantidad',
        title16: 'Tiempo',
    }
}
