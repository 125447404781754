/* 封装axios用于发送请求 */
import axios from 'axios'
import router from '@/router'
// import { Toast } from 'vant'
// import { setTimeout } from 'core-js'

// 并且设置了新对象的基地址
const baseUrl = 'https://www.pggame.gg/' // 线上

const request = axios.create({
  // baseURL: 'https://www.x.orde.live',
  // 设置超时时间为5秒
  // 意思是说如果发一个请求5秒还没反应就直接报连接超时
  timeout: 50000,
  header: {
    'Accept-Language' : localStorage.getItem('lang') || 'ti',
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
request.defaults.baseURL = baseUrl

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // JS操作cookies方法!

    if(!localStorage.getItem('lang')) {
      localStorage.setItem('lang','ti')
      localStorage.setItem('lg','ภาษาไทย')
    }

    config.headers.lang = localStorage.getItem('lang') || 'ti'
    
    if (localStorage.getItem('App-Token')) {
      // config.headers.Authorization = `Bearer ${getToken()}`
      config.headers.token = `${localStorage.getItem('App-Token')}`
    }
    

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // console.log('111', response)
    // 对响应数据做点什么,取值时少写一个data
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    // console.log(error.response.data.code)
    // console.log('555', error)
    if (error.response.data.code === 401) {
      
      localStorage.removeItem('token')
     
      setTimeout(() => {
        router.push('/')
      }, 1000)
    }
    if (error.response.status === 403) {
      // console.log(error.response.data)
      router.push({
        path: '/error',
        query: {
          info: error.response.data
        }
      })
    }
    return Promise.reject(error)
  }
)
// 'Login information invalid, please log in again'
// 暴露出去
export default request
