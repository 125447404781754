// 1、创建中文语言包对象
export default {
    heads: {
        title: 'گھر',
        title1: 'منصفانہ ثبوت',
        title2: 'کیسینو',
        title3: 'تشہیر',
        title4: 'خزانہ',
        title5: 'انعامات',
        title6: 'قوانین',
        title7: 'بیٹنگ کی واپسی',
        title8: 'ڈپازٹ کی واپسی',
        title9: 'مہم',
        title10: 'مدد',
        title11: 'لاگ ان',
        title12: 'رجسٹر کریں',
        title13: 'پرسکین',
        title14: 'ای میل',
        title15: 'اپنی ای میل درج کریں',
        title16: 'پاس ورڈ',
        title17: 'اپنا پاس ورڈ درج کریں',
        title18: 'پاس ورڈ بھول گئے؟',
        title19: 'اکاؤنٹ نہیں ہے؟ ',
        title20: 'رجسٹر کریں',
        title21: 'صارف نام',
        title22: 'اپنا صارف نام درج کریں',
        title23: 'ٹرانزیکشن پاس ورڈ',
        title24: 'اپنا ٹرانزیکشن پاس ورڈ درج کریں',
        title25: 'دعوت کوڈ',
        title26: 'اپنا دعوت کوڈ درج کریں',
        title27: 'پہلے سے ہی اکاؤنٹ ہے؟',
        title28: 'لاگ ان کریں',
        title29: 'پاس ورڈ بھول گئے',
        title30: 'ویریفیکیشن کوڈ',
        title31: 'اپنا ویریفیکیشن کوڈ درج کریں',
        title32: 'نیا پاس ورڈ',
        title33: 'اپنا نیا پاس ورڈ درج کریں',
        title34: 'پاس ورڈ کی تصدیق',
        title35: 'اپنی تصدیق شدہ پاس ورڈ درج کریں',
        title36: 'ترمیم کریں',
        title37: 'ڈپازٹ',
        title38: 'واپسی',
        title39: 'تبادلہ',
        title40: 'کرپٹو کرنسی',
        title41: 'ڈپازٹ کی رقم درج کریں',
        title42: 'تصدیق کریں',
        title43: 'پروموشن کوڈ یا گفٹ کارڈ کوڈ درج کریں',
        title44: 'ڈپازٹ کم از کم نہیں ہو سکتا',
        title45: 'ای میل خالی نہیں ہو سکتی',
        title46: 'پاس ورڈ خالی نہیں ہو سکتا',
        title47: 'ٹرانزیکشن پاس ورڈ خالی نہیں ہو سکتا',
        title48: 'ویریفیکیشن کوڈ خالی نہیں ہو سکتا',
        title49: 'نیا پاس ورڈ خالی نہیں ہو سکتا',
        title50: 'پاس ورڈ کی تصدیق خالی نہیں ہو سکتی',
        title51: 'دونوں پاس ورڈز ہم آہنگ نہیں ہیں',
        title52: 'تبادلہ کوڈ خالی نہیں ہو سکتا',
        title53: 'صارف نام خالی نہیں ہو سکتا'
    },
    ggtext: {
        title: 'کاپی کامیاب',
        title1: 'کاپی ناکام',
        title2: 'لوڈ ہو رہا ہے...',
        title3: 'مزید کچھ نہیں ہے'
    },
    rechange: {
        title: 'ڈپازٹ',
        title1: 'آپ کا',
        title2: 'ڈپازٹ ایڈریس'
    },
    withdraw: {
        title: 'واپسی',
        title1: 'واپسی کا پتہ',
        title2: 'بینک اکاؤنٹ نمبر',
        title3: 'رقم',
        title4: 'براہ کرم ٹرانزیکشن پاس ورڈ درج کریں',
        title5: 'تصدیق کریں',
        title6: 'براہ کرم پہلے USDT پتہ منسلک کریں',
        title7: 'براہ کرم پہلے بینک اکاؤنٹ نمبر منسلک کریں',
        title8: 'رقم خالی نہیں ہو سکتی',
        title9: 'ٹرانزیکشن پاس ورڈ خالی نہیں ہو سکتا'
    },
    user: {
        title: 'بینک کی معلومات منسلک کریں',
        title1: 'ملک',
        title2: 'پتہ',
        title3: 'بینک کا نام',
        title4: 'بینک اکاؤنٹ نمبر',
        title5: 'نام',
        title6: 'ای میل',
        title7: 'تصدیق کریں',
        title8: 'ترمیم کریں',
        title9: 'پتہ خالی نہیں ہو سکتا',
        title10: 'بینک کا نام منتخب کریں',
        title11: 'کارڈ نمبر درج کریں',
        title12: 'نام درج کریں',
        title13: 'ای میل درج کریں',
        title14: 'ٹریڈ',
        title15: 'سب',
        title16: 'ڈپازٹ',
        title17: 'واپسی',
        title18: 'مقدار',
        title19: 'وقت',
        title20: 'ڈپازٹ نہیں کیا',
        title21: 'کامیاب',
        title22: 'ناکام',
        title23: 'جائزہ لیا جا رہا ہے',
        title24: 'ادائیگی جاری ہے',
        title25: 'گیم ریکارڈ',
        title26: 'بیٹنگ',
        title27: 'منافع',
        title28: 'تفصیلات',
        title29: 'بینک کی معلومات منسلک کریں',
        title30: 'نوٹس',
        title31: 'شماریات',
        title32: 'ٹرانزیکشن پاس ورڈ میں تبدیلی',
        title33: 'ٹرانزیکشن ریکارڈ',
        title34: 'گیم ریکارڈ',
        title35: 'لاگ آؤٹ',
        title36: 'اپ لوڈ ناکام، براہ کرم دوبارہ اپ لوڈ کریں',
        title37: 'صارف کی معلومات',
        title38: 'صارف نام',
        title39: 'پیغام کی اطلاعات',
        title40: 'تعارف',
        title41: 'تفصیلات',
        title42: 'شماریات',
        title43: 'کھیلا گیا گیم',
        title44: 'بیٹنگ کی گئی',
        title45: 'خالص منافع',
        title46: 'گزشتہ 24 گھنٹوں کی شماریات',
        title47: 'گیم',
        title48: 'زیادہ سے زیادہ بیٹنگ',
        title49: 'زیادہ سے زیادہ منافع',
        title50: 'ویریفیکیشن کوڈ',
        title51: 'نیا پاس ورڈ',
        title52: 'پاس ورڈ کی تصدیق',
        title53: 'ای میل خالی نہیں ہو سکتی',
        title54: 'ویریفیکیشن کوڈ خالی نہیں ہو سکتا',
        title55: 'نیا پاس ورڈ خالی نہیں ہو سکتا',
        title56: 'پاس ورڈ کی تصدیق خالی نہیں ہو سکتی',
        title57: 'دونوں پاس ورڈز ہم آہنگ نہیں ہیں'
    },
    Activity: {
        title: 'مدعو کرنے کا لنک',
        title1: 'مؤثر داخلی',
        title2: 'افراد',
        title3: 'مؤثر طور پر بڑھائے گئے کھلاڑیوں کی تعداد کیا ہے؟',
        title4: 'ذیلی ملازمین کی مجموعی جمع رقم',
        title5: '2 یا اس سے زیادہ',
        title6: 'ایس سیوآرڈینیٹاکم واؤ ٹو دی اوسٹاس',
        title7: '72 یا اس سے زیادہ'
    },
    award: {
        title: 'ناکامی کی رقم سے زیادہ',
        title1: 'تحفے کی رقم'
    },
    Betting: {
        title: 'بیٹنگ ٹرانزیکشن واپسی انعامات',
        title1: 'پہلا درجے',
        title2: 'دوسرا درجے',
        title3: 'تیسرا درجے'
    },
    home: {
        title: 'خوش آمدید',
        title1: 'براہ کرم تلاش کا مواد درج کریں',
        title2: 'کیسینو',
        title3: 'گیمز تک رسائی',
        title4: 'اسپورٹس بیٹنگ',
        title5: 'حالیہ جیتیں',
        title6: 'کل بیٹنگ',
        title7: 'لائیو بیٹنگ',
        title8: 'ہائی رولرز',
        title9: 'خوش قسمت جیت',
        title10: 'گیم',
        title11: 'صارف',
        title12: 'وقت',
        title13: 'رقم'
    },
    games: {
        title: 'رسمی',
        title1: 'ٹیسٹ کھیل',
        title2: 'گیم شروع کریں',
        title3: 'گیم کی آزمائش دستیاب نہیں'
    },
    Playground: {
        title: 'کیسینو',
        title1: 'براہ کرم تلاش کا مواد درج کریں',
        title2: 'خوش قسمتی سے انتخاب',
        title3: 'سپلائر',
        title4: 'معیارات کے مطابق درجہ بندی',
        title5: 'اوپر واپس جائیں'
    },
    Promotion: {
        title: 'تشہیر شراکت دار',
        title1: 'نیچے دیے گئے کوڈ کو تخلیق کریں اور مفت انعامات جیتیں',
        title2: 'مدعو کرنے کا لنک',
        title3: 'براہ راست سفارش کی تعداد',
        title4: 'پہلی بار جمع کرنے والے افراد',
        title5: 'جمع کرنے والے افراد',
        title6: 'ٹیم کی مجموعی جمع رقم',
        title7: 'ٹیم کی کل رقم',
        title8: 'ٹیم کے ارکان',
        title9: 'ڈیٹا کا جائزہ',
        title10: 'ٹیم',
        title11: 'دیگر ارکان',
        title12: 'ٹرانزیکشن',
        title13: 'مجموعی کاروبار',
        title14: 'براہ راست سفارش کی ٹرانزیکشن',
        title15: 'دیگر ٹرانزیکشن',
        title16: 'کمیٹی',
        title17: 'کل کمیشن',
        title18: 'براہ راست سفارش کا کمیشن',
        title19: 'دیگر کمیشن'
    },
    RechargeRebate: {
        title: 'ڈپازٹ ٹرانزیکشن انعامات',
        title1: 'پہلی بار ڈپازٹ',
        title2: 'انعام'
    },
    rule: {
        title: 'کاروبار کا ہدف حاصل کرنا ضروری ہے',
        title1: 'ڈپازٹ کی رقم کی ضروریات',
        title2: 'ہفتہ وار انعامات',
        title3: 'ماہانہ انعامات',
        title4: 'رکنیت اپ گریڈ انعامات',
        title5: '*اس پلیٹ فارم کی روزانہ کی واپسی کی تعداد، روزانہ کی واپسی کی حد، اور فی ٹرانزیکشن کی حد کی کوئی حد نہیں ہے۔'
    },
    support: {
        title: 'انصاف',
        title1: 'کیا کھیل منصفانہ ہے؟',
        title2: 'ہم ایک منصفانہ اور شفاف پیش گوئی پلیٹ فارم ہیں۔ ہمارا مقصد ہر قسم کے غیر منصفانہ عوامل کو ختم کرنا ہے تاکہ کھلاڑی بے فکر اور خوشی سے کھیل سکیں۔',
        title3: 'ہم نے مجموعی طور پر 10 ملین ہیش ویلیوز تیار کی ہیں (ہیش چین قابل تصدیق ہے)، ہر ہیش ویلیو ایک Crash ملٹیپلیئر کے مساوی ہے۔',
        title4: 'ہم نے ان 10 ملین نمبروں کو الٹی ترتیب میں جاری کیا، ہر نمبر کھیل کے ایک راؤنڈ کے مساوی ہے (یعنی ہمارے پاس کل 10 ملین راؤنڈز ہیں)۔',
        title5: 'دوسرے الفاظ میں، ہر راؤنڈ کی ٹکراو کی تعداد پہلے سے موجود ہے، یہ کھیل شروع ہونے کے بعد نہیں شمار کی جاتی، لہذا کھلاڑی بے فکر ہو کر بیٹنگ کر سکتے ہیں۔',
        title6: 'کیا کھیل کو پلیٹ فارم کے ذریعہ ہیرا پھیری کیا جائے گا؟',
        title7: 'کمپلیٹنس چیک ویلیو یہ تصدیق کرنے کی کلید ہے کہ آیا سرکاری ہیرا پھیری موجود ہے؛ ٹیسٹ الگورڈم درج ذیل ہے۔',
        title8: 'مثال: 6b5124897c3c48d0e46cc9249f08c7e560792459f1bad1171224643b5d2be231',
        title9: 'ایک 2^52 رینج میں بے ترتیب ویلیو لیں، یعنی 16^13، یعنی 13 ہندسوں کی ہیکس ڈیسیمل ویلیو (کیونکہ ہیش ویلیو ہیکس ڈیسیمل ہے، اس لئے 2^52===16^13) 6b5124897c3c4 (0x6b5124897c3c4 کے دسواری میں 1887939992208324 کے مساوی ہے)۔',
        title10: 'بے ترتیب ویلیو کو 0~1 کے درمیان تقسیم کریں، یعنی 13 fs میں سب سے بڑی ویلیو 0x6b5124897c3c4/0x100000000000000 سے تقسیم کریں۔ ہیش ویلیو کی بے ترتیب نوعیت کو مدنظر رکھتے ہوئے، ہم سمجھ سکتے ہیں کہ کوئی بھی ہیش ویلیو 0~1 کے درمیان بے ترتیب نمبر میں تبدیل ہو سکتی ہے، 1887939992208324/4503599627370496 = 0.419206889692064۔',
        title11: 'ڈیلر کا فائدہ 1% فرض کریں۔ مزید حساب کریں 99/(1-X)، جہاں X مرحلہ 2 میں حساب کی گئی بے ترتیب ویلیو ہے۔ جب X 0 ہو، نتیجہ 99 ہوگا؛ جب X 1 ہو، نتیجہ لامحدود ہوگا؛ جب X 0.01 ہو، نتیجہ 100 ہوگا؛ جب X 0.01 سے کم ہو، نتیجہ 100 سے کم ہوگا۔',
        title12: 'نتیجہ: مجموعی طور پر بے ترتیب نمبر کی تقسیم 99 سے لامحدود تک ہے؛ اور جب بے ترتیب نمبر کی تقسیم 0~0.01 ہے تو نتیجہ 100 سے کم ہے۔',
        title13: '99/(1-0.419206889692064) = 170.45656748150867 تمام 100 سے کم ویلیوز کو 100 پر سیٹ کیا جائے گا۔ دوسرے الفاظ میں، 100 کے ظاہر ہونے کا امکان 1% ہے۔ نمبر کو گرد کرو اور 100 سے تقسیم کریں تاکہ آخری نتیجہ حاصل ہو۔ 170/100 = 1.70',
        title14: 'نتیجہ: کھیل میں استعمال ہونے والی ہیش ویلیو الٹی ترتیب میں ہے، اس لئے SHA256 الگورڈم کے ذریعے، آپ کھیل میں استعمال ہونے والی پچھلی ہیش ویلیو سے اس ہیش ویلیو کی اگلی ہیش ویلیو حساب کر سکتے ہیں۔ چونکہ ہیش ویلیو کی چابی اور ویلیو ایک دوسرے کے ساتھ ہم آہنگ ہیں، ہم یہ نتیجہ اخذ کر سکتے ہیں: اگر ایک ہیش ویلیو اس کی پچھلی ہیش ویلیو کا حساب لگا سکتی ہے، تو اس ہیش ویلیو کو پچھلی انعام کی اشاعت کے وقت پہلے ہی تیار کیا جانا چاہئے۔ اسی طرح، پورا ہیش چین شروع میں تیار کیا جاتا ہے، ایک بار تیار ہونے کے بعد اسے تبدیل نہیں کیا جا سکتا، ورنہ یہ SHA256 کی تصدیق کو پاس نہیں کرے گا، انعامات کے حوالے سے، یہ صرف ایک احتمال کا کھیل ہے، Crash کا ہونا ناگزیر ہے، اور سرکاری منتظمین کسی بھی کھیل کی ترتیب کو ہیرا پھیری نہیں کر سکتے۔ لہذا، CRASH دیگر جوا کے طریقوں سے زیادہ شفاف ہے، یہ ہماری کھیل کی بنیاد ہے۔',
        title15: 'سادہ حساب: ',
        title16: 'جب 13 ہندسوں کی ہیش ویلیو 8000000000000 = 1.98، 0xb000000000 = 3.168، 0xc000000000 = 3.96 ہو، یعنی پہلی ہندسہ 8 سے زیادہ ہے ((16-8)/16≈0.5)، نتیجہ تقریباً 2x؛ جب پہلی ہندسہ b سے زیادہ ہو ((16-11)/16≈0.3)، نتیجہ تقریباً 3x؛ جب پہلی ہندسہ c سے زیادہ ہو ((16-12)/16≈0.25)، نتیجہ تقریباً 4x؛ باقی اسی طرح۔',
        title17: 'جب 13 ہندسوں کی ہیش ویلیو f000000000000 = 15.84، ff00000000000 = 253.44، fff000000',
        title18: 'انصاف کا ثبوت',
        title19: 'ہم سے رابطہ کریں',
        title20: 'عمومی سوالات',
        title21: 'آن لائن سپورٹ'
    },
    Treasure: {
        title: 'دوستوں کو مدعو کریں اور خزانہ حاصل کریں',
        title1: 'تاریخ',
        title2: 'حاصل شدہ',
        title3: 'حاصل کریں',
        title4: 'غیر حاصل شدہ',
        title5: 'فعالیت کا وقت (UTC-3):',
        title6: '2024/04/26 00:00:00-2028/04/30 23:59:59 دوسرا، فعالیت کی شرائط:',
        title7: 'صرف نئے رپورٹ شدہ ماتحت اور ذیلی افراد ہی اس سرگرمی میں حصہ لینے کے اہل ہیں۔ تین: ',
        title8: '1.دوستوں کو مدعو کریں اور خزانہ کھولیں، مختلف تعداد میں مدعو کرنے سے متعلق خزانہ حاصل کریں، زیادہ سے زیادہ رقم 2 ملین، جتنا زیادہ دوست مدعو کریں گے، انعامات اتنے ہی زیادہ ہوں گے؛',
        title9: '2. یہ سرگرمی پلیٹ فارم کی اضافی پیشکش ہے، آپ دیگر انعامات اور ایجنٹ کمیشن کے ساتھ لطف اندوز ہو سکتے ہیں، یعنی مختلف قسم کی خوشی سے لطف اندوز ہوں؛',
        title10: '3. انعامات صرف ایپ/iOS، APP/Android پر دستی طور پر وصول کیے جا سکتے ہیں، اور ایکسپائرڈ انعامات خود بخود جاری کیے جائیں گے؛',
        title11: '4. اس سرگرمی میں جاری کردہ انعامات (بنیادی نہیں) کو درست بیٹنگ کے ساتھ تبدیل کرنا ضروری ہے، بیٹنگ کی حد صرف سلاٹ مشینیں: تمام کھیل؛',
        title12: '5. یہ سرگرمی صرف اکاؤنٹ ہولڈرز کے لیے معمول کے مطابق عمل میں ہے، کرایہ، ہیڈنگ، بوٹس، مختلف اکاؤنٹس پر بیٹنگ، باہمی اسٹینڈ، ثالثی، انٹرفیس، معاہدے، خلاف ورزی، گروپ کنٹرول وغیرہ کے تکنیکی طریقوں کی شرکت کی ممانعت ہے، بصورت دیگر انعامات منسوخ یا کٹوتی کی جائے گی، اکاؤنٹ منجمد کیا جا سکتا ہے اور یہاں تک کہ بلیک لسٹ میں بھی شامل کیا جا سکتا ہے؛',
        title13: '6. متن کی وضاحت میں اختلاف سے بچنے کے لیے، پلیٹ فارم اس سرگرمی کی آخری تشریح کے حقوق رکھتا ہے۔',
        title14: 'خزانہ وصول کرنے کا ریکارڈ',
        title15: 'مقدار',
        title16: 'وقت'
    }
    
}
